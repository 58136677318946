import all_styles from './style_templates';

/** type_id which are only present for old bulletins, and should not be allowed in new bulletins. */
export const deprecated_types = ['redirect', 'redirect_2', 'text_only'];

export const types = {
  //DEPRECATED
  redirect: {
    id: 'redirect',
    parts: [
      { type: 'OrganizationProfile' },
      { type: 'RichText', text_type: 'title' },
      { type: 'Map', fullscreen: false },
      { type: 'RichText', text_type: 'body' },
    ],
  },
  redirect_2: {
    id: 'redirect_2',
    parts: [
      { type: 'OrganizationProfile' },
      { type: 'Map', fullscreen: false },
      { type: 'RichText', text_type: 'title' },
      { type: 'RichText', text_type: 'body' },
    ],
  },
  text_only: {
    id: 'text_only',
    parts: [
      { type: 'OrganizationProfile' },
      { type: 'RichText', text_type: 'title' },
      { type: 'RichText', text_type: 'body' },
    ],
  },

  //
  //

  map_only: {
    id: 'map_only',
    parts: [{ type: 'OrganizationProfile' }, { type: 'Map', fullscreen: true }],
  },

  map_with_title: {
    id: 'map_with_title',
    parts: [
      { type: 'OrganizationProfile' },
      { type: 'Text', paragraphs: [{ style: 'title_medium' }] },
      { type: 'RouteIcons' },
      { type: 'Text', paragraphs: [{ style: 'subtitle' }] },
      { type: 'Map', fullscreen: false },
      { type: 'QR' },
    ],
  },

  redirect_v2: {
    id: 'redirect_v2',
    parts: [
      { type: 'OrganizationProfile' },
      { type: 'Text', paragraphs: [{ style: 'title_medium' }] },
      { type: 'RouteIcons' },
      { type: 'Text', paragraphs: [{ style: 'subtitle' }] },
      { type: 'Map', fullscreen: false },
      { type: 'Text', paragraphs: [{ style: 'text_medium' }] },
      { type: 'QR' },
    ],
  },
  redirect_2_v2: {
    id: 'redirect_2_v2',
    parts: [
      { type: 'OrganizationProfile' },
      { type: 'RouteIcons' },
      { type: 'Map', fullscreen: false },
      {
        type: 'Text',
        paragraphs: [{ style: 'title_medium' }, { style: 'text_medium' }],
      },
      { type: 'QR' },
    ],
  },
  text_only_v2: {
    id: 'text_only_v2',
    parts: [
      { type: 'OrganizationProfile' },
      {
        type: 'Text',
        paragraphs: [{ style: 'title_medium' }],
      },
      { type: 'RouteIcons' },
      {
        type: 'Text',
        paragraphs: [{ style: 'text_medium' }],
      },
      { type: 'QR' },
    ],
  },
};

export const validDocTypes = Object.keys(types).filter(
  (t) => !deprecated_types.includes(t),
);

export const props = {
  meta: {
    description: {
      undo_behavior: 'on_prop_change',
    },
    name: {
      undo_behavior: 'on_prop_change',
    },
    folder: {
      undo_behavior: 'every',
    },
  },
  settings: {
    text_0: {
      undo_behavior: 'on_prop_change',
    },
    text_1: {
      undo_behavior: 'on_prop_change',
    },
    text_2: {
      undo_behavior: 'on_prop_change',
    },
    text_3: {
      undo_behavior: 'on_prop_change',
    },
    text_4: {
      undo_behavior: 'on_prop_change',
    },
    style_id: {
      undo_behavior: 'every',
    },
    type_id: {
      undo_behavior: 'every',
    },
    map_0: {
      undo_behavior: 'every',
    },
    map_1: {
      undo_behavior: 'every',
    },
    map_2: {
      undo_behavior: 'every',
    },
    map_3: {
      undo_behavior: 'every',
    },
    read_more_url: {
      undo_behavior: 'every',
    },
    link_option_id: {
      undo_behavior: 'every',
    },
    affected_routes: {
      undo_behavior: 'on_prop_change',
    },
  },
};

export const getDefaultSettings = (config) => {
  //This is a function to ensure a new objects without references is returned.
  if (!config) {
    throw new Error('getDefaultSettings requires config as first parameter.');
  }
  const type_id = config.default_bulletin_type;
  let my_org = window.sso.getJWT().getClaim('org');
  if (my_org === 'allbinary') {
    //If user is allbinary get the org we are developing for from the service_name.
    const svc_parts = config.service_name.split('_');
    my_org = svc_parts.length > 1 ? svc_parts[1] : '';
  }
  let style_id = undefined;
  if (
    config.default_bulletin_style_by_type &&
    config.default_bulletin_style_by_type[type_id]
  ) {
    style_id = config.default_bulletin_style_by_type[type_id];
  } else if (all_styles[my_org]) {
    const templates = all_styles[my_org].templates;
    templates.some((t) => {
      if (t.types.includes(type_id)) {
        style_id = t.id;
        return true;
      }
      return false;
    });
  } else {
    throw new Error(
      'Could not find a suitable style to apply to default doc type.',
    );
  }

  const settings = {
    type_id,
    style_id,
    timetable_id: 1,
    map_0: {},
  };

  if (config.default_qr_link_url) {
    settings.default_qr_link_url = config.default_qr_link_url;
  }

  let text_index = 0;
  types[type_id].parts.forEach((part) => {
    if (part.type === 'Text') {
      settings['text_' + text_index] = part.paragraphs.map((para_settings) => {
        const paragraph = { text: '' };
        if (para_settings.style) {
          paragraph.style = para_settings.style;
        }
        if (para_settings.weight) {
          paragraph.weight = para_settings.weight;
        }
        if (para_settings.justified) {
          paragraph.justified = para_settings.justified;
        }
        return paragraph;
      });
      text_index++;
    }
  });

  return settings;
};
