import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: left;
  p,
  .bool_block,
  .bool_input {
    vertical-align: middle;
    display: inline-block;
  }
  p {
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  .grid_block {
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.border};
    padding: 2px;
    max-height: 110px;
    overflow: auto;
    @media screen and (min-height: 850px) {
      max-height: 180px;
    }
  }
`;

const GridItem = styled.div`
  display: inline-block;
  border: 2px solid transparent;
  //box-shadow: 0 2px 3px rgba(38, 38, 38, 0.12);
  background-color: ${(props) => props.background};
  color: ${(props) => props.foreground};
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 6px;
  margin: 2px;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  height: 26px;
  position: relative;
  &:hover {
    border-color: #000;
  }
  &.active {
    border-color: #000;
  }
  &.circle {
    padding: unset;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    .border_box {
      border-radius: 50%;
    }
  }
  &.square {
    border-radius: 0px;
    .border_box {
      border-radius: 0px;
    }
  }
  .border_box {
    border: 1px solid #fff;
    border-radius: 4px;
    height: 22px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

class RouteGridSelect extends React.Component {
  render() {
    return (
      <Container className="user_input bool_input">
        <div className="grid_block">
          {this.props.options.map((item, i) => {
            let shape = item.label.length > 2 ? 'square' : item.shape;
            return (
              <GridItem
                background={item.background}
                foreground={item.foreground}
                key={i}
                className={'route_style ' + shape}
                onClick={() => {
                  this.props.onClick(item);
                }}
              >
                <div className="border_box" />
                {item.label}
              </GridItem>
            );
          })}
        </div>
      </Container>
    );
  }
}

export default RouteGridSelect;
