import withStore from 'with-store';
import oh from 'output-helpers';
import api_helpers from './api_helpers';
import * as doc_definitions from '../doc_definitions';

let thumbnail_requests = {};

export function getUser(user_id, users_state) {
  if (users_state.by_id.hasOwnProperty(user_id) === false) {
    throw new Error('User ID missing in state!');
  }
  return users_state.by_id[user_id];
}
export function resolve(doc_in_state, users_state, image_state) {
  let change_log = [];
  doc_in_state.meta_log.forEach((log, i) => {
    change_log.push(
      Object.assign({}, log, {
        type: 'meta',
        index: i,
        user: getUser(log.user_id, users_state),
      }),
    );
  });
  doc_in_state.timemachine_log.forEach((tm, i) => {
    change_log.push({
      date: tm.date,
      user_id: tm.user_id,
      user: getUser(tm.user_id, users_state),
      type: 'timemachine',
      index: i,
    });
  });

  let tn = withStore.getState().config.default_thumbnail;

  if (image_state.by_id.hasOwnProperty(doc_in_state.id)) {
    tn = image_state.by_id[doc_in_state.id].base64_data;
  } else {
    if (thumbnail_requests.hasOwnProperty(doc_in_state.id) === false) {
      thumbnail_requests[doc_in_state.id] = true;
      api_helpers
        .fetchThumbnail(doc_in_state.id)
        .then((base64_thumbnail) => {
          withStore.actions.image.setThumbnail({
            id: doc_in_state.id,
            base64_data: base64_thumbnail,
          });
          delete thumbnail_requests[doc_in_state.id];
        })
        .catch((err) => {
          console.error('Error fetching thumbnail:');
          console.error(err);
          withStore.actions.image.setThumbnail({
            id: doc_in_state.id,
            base64_data: tn,
          });
          delete thumbnail_requests[doc_in_state.id];
        });
    }
  }
  return Object.assign({}, doc_in_state, {
    created_by: getUser(doc_in_state.creator_id, users_state),
    meta_log: doc_in_state.meta_log.map((log) =>
      Object.assign({}, log, { user: getUser(log.user_id, users_state) }),
    ),
    timemachine_log: doc_in_state.timemachine_log.map((log) =>
      Object.assign({}, log, { user: getUser(log.user_id, users_state) }),
    ),
    change_log: change_log,
    tn: tn,
  });
}

export function cloneDoc(doc_id, name) {
  let global_state = withStore.getState();
  let doc = global_state.docs.by_id[doc_id];
  if (!doc) {
    throw new Error('Tried to clone document not in state.');
  }
  if (!name) {
    throw new Error('Missing name parameter when clogin document.');
  }
  let my_id = global_state.users.my_id;
  let folder_id = doc.archived ? global_state.folders.root_id : doc.folder_id;
  let clone = Object.assign({}, doc, {
    archived: false,
    creator_id: my_id,
    created: new Date().toISOString(),
    folder_id: folder_id,
    meta_log: [
      {
        date: new Date().toISOString(),
        user_id: my_id,
        change_type: 'created_by_cloning',
        from: doc.name,
      },
    ],
    name: name,
    timemachine_log: [],
  });
  return api_helpers.createDocument(clone);
}

export function createTimemachineAndSaveToServer(doc) {
  let global_state = withStore.getState();

  let altered_doc = Object.assign({}, doc);
  altered_doc.settings = Object.assign(
    {},
    altered_doc.settings,
    altered_doc.tmp_settings,
  );
  delete altered_doc.tmp_settings;

  let last =
    altered_doc.timemachine_log.length > 0
      ? altered_doc.timemachine_log[altered_doc.timemachine_log.length - 1]
      : {};
  let updated_keys = Object.keys(altered_doc.settings);
  let changed_keys = updated_keys.filter(
    (updated_key) =>
      altered_doc.timemachine_log[updated_key] === last[updated_key],
  );

  let tm_doc = Object.assign({}, altered_doc, {
    settings: Object.assign({}, altered_doc.settings),
  });
  tm_doc = packDocumentSettings(tm_doc);

  altered_doc.timemachine_log.push({
    user_id: global_state.users.my_id,
    date: new Date().toISOString(),
    changed_keys: changed_keys,
    settings: JSON.stringify(tm_doc.settings),
  });

  return api_helpers.saveDocument(altered_doc);
}

export function packLogs(logs) {
  return [...logs].map((log) => {
    const log_clone = Object.assign({}, log);
    delete log_clone.user;
    return log_clone;
  });
}

export function packMapSetting(map_set) {
  return Object.assign({}, map_set);
}

export function packRichTextSetting(text_setting) {
  return Object.assign({}, text_setting);
}

export function packTextSetting(text_setting) {
  return text_setting.map((p) => Object.assign({}, p));
}

export function packDocumentSettings(doc) {
  let settings = Object.assign({}, doc.settings);
  if (doc.settings.hasOwnProperty('map_0')) {
    settings['map_0'] = packMapSetting(doc.settings['map_0']);
  }
  for (let i = 0; i < 5; i++) {
    if (doc.settings.hasOwnProperty('title_' + i)) {
      settings['title_' + i] = packRichTextSetting(doc.settings['title_' + i]);
    }
    if (doc.settings.hasOwnProperty('body_' + i)) {
      settings['body_' + i] = packRichTextSetting(doc.settings['body_' + i]);
    }
    //V2
    if (doc.settings.hasOwnProperty('text_' + i)) {
      settings['text_' + i] = packTextSetting(doc.settings['text_' + i]);
    }
  }

  let tmp_settings = Object.assign({}, doc.tmp_settings);
  if (tmp_settings.hasOwnProperty('map_0')) {
    tmp_settings['map_0'] = packMapSetting(tmp_settings['map_0']);
  }
  for (let i = 0; i < 5; i++) {
    if (tmp_settings.hasOwnProperty('title_' + i)) {
      tmp_settings['title_' + i] = packRichTextSetting(
        tmp_settings['title_' + i],
      );
    }
    if (tmp_settings.hasOwnProperty('body_' + i)) {
      tmp_settings['body_' + i] = packRichTextSetting(
        tmp_settings['body_' + i],
      );
    }
    //V2
    if (tmp_settings.hasOwnProperty('text_' + i)) {
      tmp_settings['text_' + i] = packTextSetting(tmp_settings['text_' + i]);
    }
  }

  doc.settings = settings;
  doc.tmp_settings = tmp_settings;

  return doc;
}

export function packDocumentForServer(doc) {
  let doc_copy = {
    archived: doc.archived,
    created: doc.created,
    creator_id: doc.creator_id,
    description: doc.description,
    folder_id: doc.folder_id,
    meta_log: doc.meta_log,
    name: doc.name,
    timemachine_log: doc.timemachine_log,
    settings: doc.settings,
    tmp_settings: doc.tmp_settings,
  };

  //V2+
  if (doc.structure_version) {
    doc_copy.structure_version = doc.structure_version;
  }

  doc_copy = packDocumentSettings(doc_copy);
  doc_copy.timemachine_log = packLogs(doc_copy.timemachine_log);
  doc_copy.meta_log = packLogs(doc_copy.meta_log);

  const style = withStore.extensions.getStyle(doc_copy.settings.style_id);

  return {
    id: doc.id,
    last_modified: doc.last_modified,
    width: style.width,
    height: style.height,
    data: doc_copy,
  };
}

function unpackMapSetting(setting) {
  return Object.assign({}, setting);
}

function unpackRichTextSetting(setting) {
  return Object.assign({}, setting);
}
function unpackTextSetting(setting) {
  return setting.map((p) => Object.assign({}, p));
}

export function unpackDocumentSettings(doc, config) {
  if (doc.settings.hasOwnProperty('map_0')) {
    doc.settings['map_0'] = unpackMapSetting(doc.settings['map_0']);
  }
  for (let i = 0; i < 5; i++) {
    if (doc.settings.hasOwnProperty('title_' + i)) {
      doc.settings['title_' + i] = unpackRichTextSetting(
        doc.settings['title_' + i],
      );
    }
    if (doc.settings.hasOwnProperty('body_' + i)) {
      doc.settings['body_' + i] = unpackRichTextSetting(
        doc.settings['body_' + i],
      );
    }
    //V2
    if (doc.settings.hasOwnProperty('text_' + i)) {
      doc.settings['text_' + i] = unpackTextSetting(doc.settings['text_' + i]);
    }
  }
  if (!doc.settings.hasOwnProperty('read_more_url')) {
    doc.settings.read_more_url = config.default_qr_link_url;
  }

  if (doc.tmp_settings.hasOwnProperty('map_0')) {
    doc.tmp_settings['map_0'] = unpackMapSetting(doc.tmp_settings['map_0']);
  }
  for (let i = 0; i < 5; i++) {
    if (doc.tmp_settings.hasOwnProperty('title_' + i)) {
      doc.tmp_settings['title_' + i] = unpackRichTextSetting(
        doc.tmp_settings['title_' + i],
      );
    }
    if (doc.tmp_settings.hasOwnProperty('body_' + i)) {
      doc.tmp_settings['body_' + i] = unpackRichTextSetting(
        doc.tmp_settings['body_' + i],
      );
    }
    //V2
    if (doc.tmp_settings.hasOwnProperty('text_' + i)) {
      doc.tmp_settings['text_' + i] = unpackTextSetting(
        doc.tmp_settings['text_' + i],
      );
    }
  }

  return doc;
}

export function unpackDocumentFromServer(server_doc, config) {
  server_doc.data.last_modified = server_doc.last_modified;
  server_doc.data.id = server_doc.id;

  server_doc.data.last_change = new Date();
  server_doc.data.saved = true;
  if (server_doc.data.hasOwnProperty('tmp_settings') === false) {
    server_doc.data.tmp_settings = {};
  }

  let ret_doc = Object.assign({}, server_doc.data);

  ret_doc = unpackDocumentSettings(ret_doc, config);

  return ret_doc;
}

export function packFolderForServer(folder) {
  let folder_copy = {
    name: folder.name,
    logs: folder.logs,
    parent_id: folder.parent_id,
  };

  return {
    id: folder.id,
    last_modified: folder.last_modified,
    data: folder_copy,
  };
}
export function unpackFolderFromServer(server_folder) {
  server_folder.data.id = server_folder.id;
  server_folder.data.last_modified = server_folder.last_modified;
  server_folder.data.flags = server_folder.flags;
  return Object.assign({}, server_folder.data);
}

export function archiveDocument(doc_id, destination_folder = null) {
  let global_state = withStore.getState();
  let doc = global_state.docs.by_id[doc_id];
  destination_folder = destination_folder || global_state.folders.archive_id;

  let log_entry = {
    date: new Date().toISOString(),
    user_id: global_state.users.my_id,
    change_type: 'archived',
    folder_id: destination_folder,
  };
  let doc_copy = Object.assign({}, doc, {
    meta_log: [].concat(doc.meta_log, log_entry),
    archived: true,
    folder_id: destination_folder,
    settings: Object.assign({}, doc.settings),
  });
  return api_helpers.saveDocument(doc_copy);
}

export function deleteDocument(doc_id, last_modified) {
  if (!doc_id || !last_modified) {
    throw new Error('Missing parameters when deleting document.');
  }
  return api_helpers.deleteDocument(doc_id, last_modified);
}

export function exportDocument(doc, png = false) {
  return api_helpers.exportDocument(doc, png);
}

export function createDocument(data, folder_id) {
  let config = withStore.getState().config;
  let my_id = withStore.getState().users.my_id;
  let new_doc = Object.assign(
    {
      creator_id: my_id,
      created: new Date().toISOString(),
      name: oh.translate('untitled_document'),
      description: {
        text: '',
      },
      archived: false,
      folder_id: folder_id,
      timemachine_log: [],
      meta_log: [],
      settings: doc_definitions.getDefaultSettings(config),
      tmp_settings: {},
      structure_version: 2,
    },
    data,
  );
  new_doc = unpackDocumentSettings(new_doc, config);

  //Add creation to log.
  new_doc.meta_log.push({
    date: new Date().toISOString(),
    user_id: my_id,
    change_type: 'created',
  });

  return api_helpers.createDocument(new_doc);
}

export function createFolder(name, parent_id) {
  let my_id = withStore.getState().users.my_id;
  let new_folder = Object.assign({
    creator_id: my_id,
    created: new Date().toISOString(),
    name: name,
    log: [],
    parent_id: parent_id,
  });

  //Add creation to log.
  new_folder.log.push({
    date: new Date().toISOString(),
    user_id: my_id,
    change_type: 'created',
  });

  return api_helpers.createFolder(new_folder);
}

export function deleteFolder(folder_id, last_modified) {
  return api_helpers.deleteFolder(folder_id, last_modified);
}
