import React from 'react';
import withStore from 'with-store';
import styled from 'styled-components';
import oh from 'output-helpers';
import Dropdown from '../../Dropdown';
import TextEditButtonGroup from './TextEditButtonGroup';
import ParagraphOrderingControls from './ParagraphOrderingControls';

import {
  align_center,
  align_right,
  align_left,
  format_bold,
  format_italic,
} from '../../../../img/icons';

const getIcon = (id) => {
  switch (id) {
    case 'weight':
      return format_bold;
    case 'align_left':
      return align_left;
    case 'align_center':
      return align_center;
    case 'align_right':
      return align_right;
    case 'italic':
      return format_italic;
    default: {
      throw new Error('Invalid icon id.');
    }
  }
};

const text_style_ids = [
  'title_xlarge',
  'title_large',
  'title_medium',
  'title_small',
  'subtitle_large',
  'subtitle',
  'text_large',
  'text_medium',
  'text_small',
];
const text_weight_ids = ['weight_bold'];
const text_italic_ids = ['italic'];
const text_align_ids = ['align_left', 'align_center', 'align_right'];

const TextBlock = styled.div`
  display: flex;
  & .textblock_left {
    flex: 1;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const TextSettings = styled.div`
  display: flex;
  margin-bottom: 4px;
  & > *:not(:first-child) {
    margin-left: 4px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  resize: vertical;
  min-height: 30px;
  max-height: 140px;
  padding: 3px !important;
  color: black !important;
  font-size: 14px !important;
  background-color: white !important;
  margin: 0 !important;
`;

/**
interface TextInputProps extends TextParagraph {
  onChange: (para: TextParagraph) => void;
  onRemove?: () => void;
  onCreate?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}
  */
const TextInput = ({
  text,
  style = 'text_medium',
  justified = 'align_left',
  weight = 'weight_normal',
  italic = 'roman',
  onChange,
  onRemove,
  onMoveUp,
  onMoveDown,
  onCreate,
}) => {
  const text_style_options = text_style_ids.map((s) => ({
    name: oh.translate(s),
    id: s,
  }));
  const text_weight_options = text_weight_ids.map((s) => ({
    name: oh.translate(s),
    id: s,
    icon: getIcon('weight'),
  }));
  const text_italic_options = text_italic_ids.map((s) => ({
    name: oh.translate(s),
    id: s,
    icon: getIcon('italic'),
  }));
  const text_align_options = text_align_ids.map((s) => ({
    name: oh.translate(s),
    id: s,
    icon: getIcon(s),
  }));
  const classes = style + ' ' + justified + ' ' + weight + ' ' + italic;

  return (
    <TextBlock>
      <div className="textblock_left">
        <TextSettings>
          <Dropdown
            selected={style}
            options={text_style_options}
            selectedOption={(item_id) => {
              onChange &&
                onChange({ style: item_id, justified, italic, weight, text });
            }}
          />
          <TextEditButtonGroup
            selected={weight}
            options={text_weight_options}
            selectedOption={(item_id) => {
              if (weight === item_id) {
                onChange &&
                  onChange({
                    style,
                    justified,
                    italic,
                    weight: 'weight_normal',
                    text,
                  });
              } else {
                onChange &&
                  onChange({ style, justified, italic, weight: item_id, text });
              }
            }}
          />
          <TextEditButtonGroup
            selected={italic}
            options={text_italic_options}
            selectedOption={(item_id) => {
              if (italic === item_id) {
                onChange &&
                  onChange({ style, justified, weight, italic: 'roman', text });
              } else {
                onChange &&
                  onChange({
                    style,
                    justified,
                    weight,
                    italic: 'italic',
                    text,
                  });
              }
            }}
          />
          <TextEditButtonGroup
            selected={justified}
            options={text_align_options}
            selectedOption={(item_id) => {
              onChange &&
                onChange({ style, italic, justified: item_id, weight, text });
            }}
          />
          <ParagraphOrderingControls
            onRemove={onRemove}
            onCreate={onCreate}
            onMoveDown={onMoveDown}
            onMoveUp={onMoveUp}
          />
        </TextSettings>
        <Textarea
          className={classes}
          value={text}
          onChange={(ev) => {
            onChange &&
              onChange({
                style,
                justified,
                weight,
                italic,
                text: ev.target.value,
              });
          }}
        />
      </div>
    </TextBlock>
  );
};

const swapElements = (arr, a, b) => {
  [arr[a], arr[b]] = [arr[b], arr[a]];
};

//type ParagraphActions = "move_up" | "move_down" | "remove" | "update" | "create";
//const updateParagraphs = (all_paragraphs: TextParagraph[], p_index: number, action: ParagraphType, value?: TextParagraph) => TextParagraph[];
const updateParagraphs = (
  all_paragraphs,
  p_index,
  action,
  value = undefined,
) => {
  const paragraphs = [...all_paragraphs];
  switch (action) {
    case 'move_up': {
      if (p_index > 0) {
        swapElements(paragraphs, p_index, p_index - 1);
      }
      return paragraphs;
    }
    case 'move_down': {
      if (p_index < paragraphs.length) {
        swapElements(paragraphs, p_index, p_index + 1);
      }
      return paragraphs;
    }
    case 'update': {
      paragraphs[p_index] = value;
      return paragraphs;
    }
    case 'remove': {
      if (paragraphs.length === 1) {
        return [];
      }
      paragraphs.splice(p_index, 1);
      return paragraphs;
    }
    case 'create': {
      paragraphs.splice(p_index + 1, 0, { text: '' });
      return paragraphs;
    }
    default: {
      throw new Error("Invalid onTextChange action type: '" + action + "'.");
    }
  }
};

/**
interface EditTextProps {
  paragraphs?: TextParagraph[];
  label: string;
  onChange: (texts: TextParagraph[]) => void;
  style_id: string;
}
 */

const Container = styled.div`
  .live_edit_section_label {
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  ${(props) => props.text_styles}
`;

const EditText = ({ style_id, label, onChange, store_ext, paragraphs }) => {
  const internal_paragraphs = paragraphs || [{ text: '' }];

  return (
    <Container
      className={'live_edit_section ' + style_id}
      text_styles={store_ext.getTextStyles(style_id)}
    >
      <div className="live_edit_section_label">{label}</div>
      {internal_paragraphs.map((paragraph, i) => (
        <TextInput
          key={'paragraph_' + i}
          {...paragraph}
          onChange={(val) =>
            onChange(updateParagraphs(internal_paragraphs, i, 'update', val))
          }
          onCreate={() =>
            onChange(updateParagraphs(internal_paragraphs, i, 'create'))
          }
          onRemove={() =>
            onChange(updateParagraphs(internal_paragraphs, i, 'remove'))
          }
          onMoveDown={
            i < internal_paragraphs.length - 1
              ? () =>
                  onChange(
                    updateParagraphs(internal_paragraphs, i, 'move_down'),
                  )
              : undefined
          }
          onMoveUp={
            i > 0
              ? () =>
                  onChange(updateParagraphs(internal_paragraphs, i, 'move_up'))
              : undefined
          }
        />
      ))}
    </Container>
  );
};

export default withStore(EditText);
