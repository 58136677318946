import Graphic_customer_hlt from '../../img/customer_graphics_hlt_logo.svg';
import Graphic_customer_hlt_information from '../../img/customer_graphics_hlt_information.svg';
import Graphic_customer_hlt_logotype from '../../img/customer/hlt/logotype.svg';
import Graphic_customer_hlt_puff from '../../img/customer/hlt/puff.svg';
import { a4_portrait } from '../standard';

let hlt_blue = '#006CA9';

export default {
  default: {
    OrganizationProfile: `
            `,
    RichText: `
                &.type_title {
                }
            `,
    Text:
      `
        &.hlt_1_v2, &.hlt_2_v2, &.hlt_1_v3, &.hlt_2_v3  {
          white-space: pre-wrap;
          word-wrap: break-word;
          & .weight_bold {
            font-weight: bold;
          }
          & .align_left {
            text-align: left;
          }
          & .align_right {
            text-align: right;
          }
          & .align_center {
            text-align: center;
          }
          & .italic {
            font-style: italic;
          }
          & .text_small, & .text_medium, & .text_large {
            color: #000000;
            background-color: #fff;
            position: relative;
            margin: 0;
            margin-top: 32px;
            font-size: 24px;
            padding: 32px 42px;
          }
          & .text_medium {
            font-size: 32px;
          }
          & .text_large {
            font-size: 40px;
          }
          & .title_small, & .title_medium, & .title_large {
            background-color: ` +
      hlt_blue +
      `;
          color: #fff;
          font-size: 136px;
          font-weight: 900;
          padding: 0 0 50px;
          width: 100%;
          margin: 0;
          }
          & .title_medium {
            font-size: 168px;
          }
          & .title_large {
            font-size: 200px;
          }
          & .subtitle {
            background-color: ` +
      hlt_blue +
      `;
          color: #fff;
          font-size: 48px;
          padding: 0 0 50px;
          width: 100%;
          margin: 0;
          }
        }
        &.hlt_2_v2 {
          & .text_small, & .text_medium, & .text_large {
            background-color: transparent;
            color: #fff;
          }
        }

        &.hlt_1_v3, &.hlt_2_v3 {
          font-family: "Open Sans", sans-serif;
          & .text_small, & .text_medium, & .text_large {
            font-weight: 700;
            color: #fff;
            background-color: transparent;
            padding: 32px 0;
          }
          & .title_small, & .title_medium, & .title_large {
            font-weight: 800;
          }
        }
      `,
  },
  templates: [
    {
      id: 'hlt_1',
      name: 'A4 Mall 1',
      types: ['redirect'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_hlt +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            right: 64px;
                            bottom: 30px;
                            width: 400px;
                            height: 82px;
                            z-index: 3;
                        }
                        .layer_2{
                            background-image: url(` +
          Graphic_customer_hlt_information +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            left: 40px;
                            bottom: 30px;
                            width: 400px;
                            height: 74px;
                            z-index: 3;
                        }
                        .layer_3{
                            display: block;
                            position: absolute;
                            border: 60px solid ` +
          hlt_blue +
          `;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          hlt_blue +
          `;
                        color: #fff;
                        width: ${a4_portrait.width}px;
                        height: ${a4_portrait.height}px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                        div.inner img {
                          position: absolute;
                        }
                    `,
        RichText:
          `
                        &.type_title {
                            background-color: ` +
          hlt_blue +
          `;
                            color: #fff;
                            font-size: 200px;
                            font-weight: 900;
                            padding: 0 0 50px;
                            width: 100%;
                            margin: 0;
                        }
                        &.type_body {
                            color: #000000;
                            background-color: #fff;
                            position: relative;
                            text-align: left;
                            margin: 0;
                            margin-top: 32px;
                            font-size: 28px;
                            padding: 32px 42px;
                            span{
                                font-size: 28px !important;
                            }
                        }
                    `,
      },
    },
    {
      id: 'hlt_1_v2',
      name: 'A4 Mall 1',
      types: ['redirect_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_hlt +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            right: 64px;
                            bottom: 30px;
                            width: 400px;
                            height: 82px;
                            z-index: 3;
                        }
                        .layer_2{
                            background-image: url(` +
          Graphic_customer_hlt_information +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            left: 40px;
                            bottom: 30px;
                            width: 400px;
                            height: 74px;
                            z-index: 3;
                        }
                        .layer_3{
                            display: block;
                            position: absolute;
                            border: 60px solid ` +
          hlt_blue +
          `;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          hlt_blue +
          `;
                        color: #fff;
                        width: ${a4_portrait.width}px;
                        height: ${a4_portrait.height}px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                        div.inner img {
                          position: absolute;
                        }
                    `,
      },
    },
    {
      id: 'hlt_2',
      name: 'A4 Mall 2',
      types: ['redirect_2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_hlt +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            right: 64px;
                            bottom: 30px;
                            width: 400px;
                            height: 82px;
                            z-index: 3;
                        }
                        .layer_2{
                            background-image: url(` +
          Graphic_customer_hlt_information +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            left: 40px;
                            bottom: 30px;
                            width: 400px;
                            height: 74px;
                            z-index: 3;
                        }
                        .layer_3{
                            display: block;
                            position: absolute;
                            border: 60px solid ` +
          hlt_blue +
          `;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          hlt_blue +
          `;
                        color: #fff;
                        width: 1240px;
                        height: 1754px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                        div.inner img {
                          position: absolute;
                        }
                    `,
        RichText:
          `
                        &.type_title {
                            background-color: ` +
          hlt_blue +
          `;
                            color: #fff;
                            font-size: 128px;
                            font-weight: 900;
                            padding: 0 0 38px;
                            width: 100%;
                            margin: 0;
                            margin-top: 60px;
                        }
                        &.type_body {
                            color: #ffffff;
                            position: relative;
                            text-align: left;
                            margin: 0;
                            font-size: 26px;
                            padding: 0;
                            span{
                                font-size: 26px !important;
                                background-color: transparent !important;
                                color: #fff !important;
                            }
                        }
                    `,
      },
    },
    {
      id: 'hlt_2_v2',
      name: 'A4 Mall 2',
      types: ['redirect_2_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_hlt +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            right: 64px;
                            bottom: 30px;
                            width: 400px;
                            height: 82px;
                            z-index: 3;
                        }
                        .layer_2{
                            background-image: url(` +
          Graphic_customer_hlt_information +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            left: 40px;
                            bottom: 30px;
                            width: 400px;
                            height: 74px;
                            z-index: 3;
                        }
                        .layer_3{
                            display: block;
                            position: absolute;
                            border: 60px solid ` +
          hlt_blue +
          `;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          hlt_blue +
          `;
                        color: #fff;
                        width: 1240px;
                        height: 1754px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                        div.inner img {
                          position: absolute;
                        }
                    `,
      },
    },
    //NEW BRANDING TEMPLATES BELOW
    {
      id: 'hlt_1_v3',
      name: 'A4 Mall 1 Ny',
      types: ['redirect_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_hlt_logotype +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            bottom: 55px;
                            width: 150px;
                            height: 150px;
                            left: 50%;
                            transform: translateX(-50%);
                            z-index: 3;
                        }
                        .layer_2{
                            background-image: url(` +
          Graphic_customer_hlt_puff +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: flex;
                            position: absolute;
                            left: calc(50% + 75px + 32px);
                            bottom: 30px;
                            width: 400px;
                            height: 200px;
                            z-index: 3;
                            padding: 32px 32px 32px 48px;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            &:before {
                              content: "Har du frågor?";
                              font-family: "Open Sans";
                              font-size: 28px;
                              font-weight: bold;
                              margin-bottom: 16px;
                            }
                            &:after {
                              content: "Ring oss gärna på 0771 33 10 30 eller chatta med oss på hallandstrafiken.se.";
                              font-family: "Open Sans";
                              font-weight: bold;
                              font-size: 18px;
                              display: inline-block;
                              text-align: center;
                            }
                        }
                        .layer_3{
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          hlt_blue +
          `;
                        color: #fff;
                        width: 1240px;
                        height: 1754px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        margin: 0 -60px;
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                        div.inner img {
                          position: absolute;
                        }
                    `,
      },
    },
    {
      id: 'hlt_2_v3',
      name: 'A4 Mall 2 Ny',
      types: ['redirect_2_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
        position: unset;
        .layer_1 {
            background-image: url(` +
          Graphic_customer_hlt_logotype +
          `);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            bottom: 55px;
            width: 150px;
            height: 150px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
        }
        .layer_2{
            background-image: url(` +
          Graphic_customer_hlt_puff +
          `);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: flex;
            position: absolute;
            left: calc(50% + 75px + 32px);
            bottom: 30px;
            width: 400px;
            height: 200px;
            z-index: 3;
            padding: 32px 32px 32px 48px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:before {
              content: "Har du frågor?";
              font-family: "Open Sans";
              font-size: 28px;
              font-weight: bold;
              margin-bottom: 16px;
            }
            &:after {
              content: "Ring oss gärna på 0771 33 10 30 eller chatta med oss på hallandstrafiken.se.";
              font-family: "Open Sans";
              font-weight: bold;
              font-size: 18px;
              display: inline-block;
              text-align: center;
            }
        }
        .layer_3{
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    `,
        document_specification:
          `
                        background-color: ` +
          hlt_blue +
          `;
                        color: #fff;
                        width: 1240px;
                        height: 1754px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        margin: -60px -60px 32px -60px;
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                        div.inner img {
                          position: absolute;
                        }
                    `,
      },
    },
  ],
};
