import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import file_system_faq_md from './GuideTexts/file_system_faq.md';
import edit_faq_md from './GuideTexts/edit_faq.md';
import map_faq_md from './GuideTexts/map_faq.md';
import oh from 'output-helpers';

const faq_data_by_section = {
  file_system: file_system_faq_md,
  map: map_faq_md,
  edit: edit_faq_md,
};

const Container = styled.div`
  z-index: 100000; //Necessary because the Folders in MainView have weird z-index.
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: grid;
  place-items: center;
  .close_btn {
    position: absolute;
    left: 50%;
    margin-left: 276px;
    top: calc(5% - 24px);
    background-color: #eb4d44;
    font-size: 22px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    color: white;
    display: grid;
    place-items: center;
  }
  .close_btn:hover {
    background-color: #ff866b;
  }
  .content {
    width: 600px;
    height: 90vh;
    background-color: white;
    cursor: default;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
      font-size: 25px;
      font-weight: bold;
      padding: 24px;
      background-color: rgba(189, 216, 255);
    }
    .subtitle {
      margin-left: 10px;
      padding-left: 10px;
      font-size: 18px;
      border-left: 2px solid #005;
    }
    .inner_content {
      flex: 1;
      overflow: auto;
      padding: 24px 24px 0 24px;
    }
    .inner_content:after {
      content: '';
      height: 24px;
      width: 100%;
      display: inline-block;
    }
    .main-content {
      padding: 24px;
      overflow-y: auto;
      h1 {
        font-weight: bold;
        font-size: 25px;
      }
      h2 {
        font-weight: bold;
        font-size: 18px;
      }
      h3 {
        font-weight: bold;
        font-size: 20px;
        margin-top: 15px;
      }
      blockquote {
        border-left: 4px solid #1563ee;
        font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial,
          sans-serif;
        line-height: 125%;
        background-color: #eee;
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 25px;

        ul {
          list-style: disc inside;
          li {
            padding-left: 5px;
            margin: 5px;
          }
        }

        ol {
          list-style: decimal inside;
          li {
            padding-left: 5px;
            margin: 10px;
          }
        }
      }
    }
  }
`;

const FaqBase = ({ onClose, app_section }) => {
  return (
    <Container onClick={() => onClose()}>
      <div
        className="content"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="close_btn" onClick={() => onClose()}>
          X
        </div>
        <div className="title">
          <span>{oh.translate('common_questions')}</span>
          <span className="subtitle">{oh.translate(app_section)}</span>
        </div>
        <div className="main-content">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {faq_data_by_section[app_section]}
          </ReactMarkdown>
        </div>
      </div>
    </Container>
  );
};

export default FaqBase;
