import withStore from 'with-store';
import api_helpers from '../helpers/api_helpers';
import simplify from '../components/base/map_module/simplify';
import moment from 'moment';

export function reload(table_period_id = null) {
  return api_helpers.fetchTablePeriods().then((table_periods) => {
    const current_date = moment();
    table_periods = table_periods.filter((t) => {
      const start_diff = current_date.diff(t.date_start, 'year');
      return start_diff === 0 || start_diff === 1;
    });

    let now = new Date().toISOString();
    let selected_table_period = null;

    if (table_period_id === null) {
      selected_table_period = table_periods.find(
        (x) => now >= x.date_start && (x.date_end ? now < x.date_end : true),
      );
      table_period_id = selected_table_period.id;
    } else {
      selected_table_period = table_periods.find(
        (x) => x.id === table_period_id,
      );
    }

    return Promise.all([
      selected_table_period,
      table_periods,
      api_helpers.fetchDistinctStops(),
      api_helpers.fetchRoutes(table_period_id),
      api_helpers.fetchStopLinks(table_period_id),
      api_helpers.fetchStopLinkShapes(table_period_id),
      api_helpers.fetchAllRouteStops(table_period_id),
    ])
      .then(
        ([
          selected_table_period,
          table_periods,
          stops,
          routes,
          stop_links,
          stop_link_shapes,
          route_stops,
        ]) => {
          const valid_period_ids = table_periods.map((t) => t.id);
          stops = stops.filter((s) =>
            valid_period_ids.includes(s.max_table_period_id),
          );

          const stops_by_id = stops.reduce((acc, curr) => {
            acc[curr.stop_id] = curr;
            return acc;
          }, {});

          let alias_by_route_short_name = routes.reduce((arr, curr) => {
            if (curr.route_alias) {
              arr[curr.route_short_name] = curr.route_alias;
            }
            return arr;
          }, {});
          let route_short_names = routes.reduce((arr, curr) => {
            if (arr.includes(curr.route_short_name) === false) {
              arr.push(curr.route_short_name);
            }
            return arr;
          }, []);

          let passing_routes_by_stop = route_stops.reduce((arr, curr) => {
            if (arr.hasOwnProperty(curr.stop_id) === false) {
              arr[curr.stop_id] = new Set();
            }
            const route_short_name = curr.route_id.split('-')[0];
            arr[curr.stop_id].add(route_short_name);
            return arr;
          }, {});
          Object.keys(passing_routes_by_stop).forEach((key) => {
            passing_routes_by_stop[key] = Array.from(
              passing_routes_by_stop[key],
            );
          });
          const stop_link_shapes_by_id = stop_link_shapes
            .sort((a, b) =>
              a.shape_pt_sequence > b.shape_pt_sequence ? 1 : -1,
            )
            .reduce((acc, pt) => {
              if (!acc[pt.shape_id]) {
                acc[pt.shape_id] = [];
              }
              acc[pt.shape_id].push({
                lat: pt.shape_pt_lat,
                lon: pt.shape_pt_lon,
              });
              return acc;
            }, {});

          const links_by_id = stop_links.reduce((a, stop_link) => {
            const stop_link_shape =
              stop_link_shapes_by_id[stop_link.stop_link_id];
            if (!stop_link_shape) {
              console.warn(
                `Missing shape for stop link ${stop_link.stop_link_id}.`,
              );
            }

            a[stop_link.stop_link_id] = stop_link_shape
              ? stop_link_shape.map(({ lat, lon }) => [lat, lon])
              : [];
            return a;
          }, {});

          route_stops.sort((a, b) => a.stop_sequence - b.stop_sequence);

          const stop_ids_by_route_id = route_stops.reduce((acc, route_stop) => {
            acc[route_stop.route_id] = acc[route_stop.route_id] || [];
            acc[route_stop.route_id].push(route_stop.stop_id);
            return acc;
          }, {});

          const link_ids_by_route_id = {};
          Object.entries(stop_ids_by_route_id).forEach(
            ([route_id, stop_ids]) => {
              const ids = [];
              for (let i = 0; i < stop_ids.length - 1; i++) {
                ids.push(stop_ids[i] + ':' + stop_ids[i + 1]);
              }
              link_ids_by_route_id[route_id] = ids;
            },
          );

          const shapes_by_route_id = Object.entries(
            link_ids_by_route_id,
          ).reduce((acc, [route_id, link_ids]) => {
            acc[route_id] = link_ids.flatMap((stop_link_id) => {
              return links_by_id[stop_link_id];
            });
            return acc;
          }, {});

          const simplified_shapes_by_route_id = Object.entries(
            shapes_by_route_id,
          ).reduce((acc, [route_id, shape]) => {
            acc[route_id] = simplify(shape, 0.00005, true);
            return acc;
          }, {});

          return {
            fetched: true,
            selected_table_period: selected_table_period,
            table_periods: table_periods,
            stops: stops_by_id,
            routes: routes,
            shapes_by_route: simplified_shapes_by_route_id,
            passing_routes_by_stop: passing_routes_by_stop,
            alias_by_route_short_name: alias_by_route_short_name,
            route_short_names: route_short_names,
          };
        },
      )
      .then((new_state) => {
        withStore.actions.gtfs.set(new_state);
        return true;
      });
  });
}

window.gtfs_reload = reload;
