import React from 'react';
import styled from 'styled-components';
import withStore from 'with-store';
import RouteIcon from './RouteIcon';

const Container = styled.div`
  ${(props) => props.styles}
`;

const RouteIcons = ({ affected_routes, store_ext, style_id }) => {
  const styles = store_ext.getStyles(style_id)['RouteIcons'];
  if (!Array.isArray(affected_routes) || affected_routes.length === 0) {
    return null;
  }

  return (
    <Container className="route_icons" styles={styles}>
      {affected_routes.map((route) => {
        return <RouteIcon {...route} />;
      })}
    </Container>
  );
};

export default withStore(RouteIcons);
