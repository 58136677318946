import React from 'react';
import styled, { keyframes } from 'styled-components';
import oh from 'output-helpers';
const IconDown = require('react-icons/lib/md/keyboard-arrow-down');
const IconNoStyle = require('react-icons/lib/md/format-color-reset');

const fadeIn = keyframes`
            from {
              opacity: 0;
              transform: translate3d(0, -10%, 0);
            }
            to {
              opacity: 1;
              transform: none;
            }
        `;

const m = {
  list_border_radius: 4,
};

const Container = styled.div`
  display: flex;
  position: relative;
  width: ${(props) => (props.block ? '100%' : 'unset')};
  .dropwdown_label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 8px;
    font-weight: 700;
  }
  .focus_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .dropdown_contain {
    overflow: hidden;
    position: relative;
    width: ${(props) => (props.block ? '100%' : 'unset')};
    z-index: 13;
    color: ${(props) => (props.styled ? '#fff' : props.theme.colors.text)};
    cursor: pointer;
    border: 1px solid
      ${(props) =>
        props.styled ? props.theme.colors.brand[0] : props.theme.colors.border};
    border-radius: ${(props) =>
      props.styled ? '200px' : props.theme.sizes.general.input_radius + 'px'};
    display: inline-block;
    transition: all ${(props) => props.theme.sizes.general.transition_time}s;
    &.active,
    &:hover {
      background-color: ${(props) =>
        props.styled ? props.theme.colors.brand[0] : '#fff'};
      border-color: ${(props) =>
        props.styled
          ? props.theme.colors.brand[1]
          : props.theme.colors.brand[2]};
      .dropdown_arrow {
        border-color: ${(props) =>
          props.styled
            ? props.theme.colors.brand[1]
            : props.theme.colors.brand[2]};
        color: ${(props) =>
          props.styled
            ? props.theme.colors.brand[1]
            : props.theme.colors.brand[2]};
      }
    }
    &.active {
      background-color: #1463ee;
      color: #fff;
      border-radius: 4px 4px 0 0;
      .dropdown_arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    p,
    .dropdown_arrow {
      display: table-cell;
    }
    p {
      padding: ${(props) => props.theme.sizes.general.input_padding};
      font-size: 16px;
      width: ${(props) => (props.block ? '100%' : 'unset')};
      white-space: nowrap;
    }
    .dropdown_arrow {
      padding: 0 8px 0 8px;
      transition: all ${(props) => props.theme.sizes.general.transition_time}s;
      width: 40px;
      border-left: 1px solid
        ${(props) =>
          props.styled
            ? props.theme.colors.brand[0]
            : props.theme.colors.border};
      svg {
        transition: all 0.3s;
        width: 26px;
        height: 26px;
      }
    }
  }
  .dropdown_options {
    border: 1px solid
      ${(props) =>
        props.styled
          ? props.theme.colors.brand[1]
          : props.theme.colors.brand[2]};
    border-top: none;
    z-index: 9999;
    animation: ${fadeIn} 0.5s 0s both;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: 0;
    top: 34px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    ul {
      li {
        cursor: pointer;
        padding: 8px 14px;
        color: ${(props) => props.theme.colors.text};
        border-bottom: 1px solid ${(props) => props.theme.colors.border};
        &:first-child {
          //border-radius: ${m.list_border_radius +
          'px'} ${m.list_border_radius + 'px'} 0 0;
        }
        &:last-child {
          border-radius: 0 0 ${m.list_border_radius + 'px'}
            ${m.list_border_radius + 'px'};
          border-bottom: none;
        }
        &:hover {
          background-color: ${(props) => props.theme.colors.brand[2]};
          color: #fff;
        }
        &.chosen {
          background-color: rgb(221, 239, 255);
          &:hover {
            background-color: ${(props) => props.theme.colors.brand[2]};
          }
        }
        p {
          pointer-events: none;
        }
      }
    }
  }
  .dropdown_options_empty {
    padding: 18px 12px;
    color: #fff;
    background-color: #3d496c;
    text-align: center;
    font-size: 12px;
    .empty_icon {
      margin: 0 0 8px 0;
      font-size: 50px;
    }
  }
`;

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      render_options: false,
    };
  }

  getIcon(index) {
    switch (index) {
      case 0:
        return <IconNoStyle />;
      default:
        return <IconNoStyle />;
    }
  }

  render() {
    let options = [];
    let buff_options = [];
    if (this.props.options) {
      buff_options = [];
      if (typeof this.props.options[0] === 'string') {
        this.props.options.forEach((option) => {
          buff_options.push({
            id: option,
            name: option,
          });
        });
        options = buff_options;
      } else {
        options = this.props.options;
      }
    }
    let selected_name = options.filter((obj) => obj.id === this.props.selected);
    if (selected_name.length > 0 && selected_name[0].name) {
      selected_name = selected_name[0].name;
    } else {
      selected_name = oh.translate('incompatable_option');
    }

    return (
      <Container
        className="dropdown"
        styled={this.props.styled}
        block={this.props.block}
      >
        {this.state.render_options ? (
          <div
            className="focus_container"
            onClick={() =>
              this.setState({
                render_options: false,
              })
            }
          />
        ) : null}
        {this.props.label ? (
          <p className="dropwdown_label">{this.props.label}</p>
        ) : null}
        <div
          className={`dropdown_contain ${
            this.state.render_options ? 'active' : ''
          }`}
          onClick={() =>
            this.setState({ render_options: !this.state.render_options })
          }
        >
          <p>{selected_name}</p>
          <div className="dropdown_arrow">
            <IconDown />
          </div>
        </div>
        {this.state.render_options ? (
          <div className="dropdown_options">
            <ul>
              {options.length > 0 ? (
                options.map((option, i) => {
                  return (
                    <li
                      className={`${
                        option.id === this.props.selected ? 'chosen' : ''
                      }`}
                      key={i}
                      onClick={() => {
                        this.setState({ render_options: false });
                        this.props.selectedOption(option.id);
                      }}
                    >
                      <p>{option.name}</p>
                    </li>
                  );
                })
              ) : (
                <div className="dropdown_options_empty">
                  <div className="empty_icon">
                    {this.getIcon(this.props.empty_icon)}
                  </div>
                  {this.props.empty_message}
                </div>
              )}
            </ul>
          </div>
        ) : null}
      </Container>
    );
  }
}
