import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  p {
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  input,
  textarea {
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: ${(props) => props.theme.sizes.general.input_radius + 'px'};
    font-size: 16px;
    padding: ${(props) => props.theme.sizes.general.input_padding};
    width: 100%;
    transition: all ${(props) => props.theme.sizes.general.transition_time}s;
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.colors.brand[2]};
    }
  }
`;

class Input extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <Container
        className={'type_' + this.props.text_type}
        style={this.props.style}
      >
        {this.props.label ? <p>{this.props.label}</p> : null}
        {this.props.type === 'textarea' ? (
          <textarea
            value={this.props.value}
            onChange={(e) => this.props.onChange(e)}
            placeholder={this.props.placeholder}
          ></textarea>
        ) : (
          <input
            value={this.props.value}
            onChange={(e) => this.props.onChange(e)}
            placeholder={this.props.placeholder}
          />
        )}
      </Container>
    );
  }
}

export default Input;
