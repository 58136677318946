import axios from 'axios';
import withStore from 'with-store';
import withConfig from 'with-config';
import * as doc_helpers from '../helpers/document_helpers';

let config = null;
let api_funcs = {
  fetchPreview: (doc_id) => {
    const url = config.api.doc.api_uri + '/png';
    let doc = withStore.extensions.getDoc(doc_id);
    let style_id = doc.hasOwnProperty('settings')
      ? doc.settings.style_id
      : null;
    style_id = style_id ? style_id : doc.settings.style_id;
    let style = withStore.extensions.getStyle(style_id);
    let params = {
      height: style.height,
      width: style.width,
      render_url: encodeURI(config.root_uri + '/render/' + doc_id),
    };
    return apiCall(url, 'GET', null, params, 'blob');
  },
  fetchPreviewTmp: (doc_id) => {
    const url = config.api.doc.api_uri + '/png';
    let doc = withStore.extensions.getDoc(doc_id);
    let style_id = doc.hasOwnProperty('tmp_settings')
      ? doc.tmp_settings.style_id
      : null;
    style_id = style_id ? style_id : doc.settings.style_id;
    let style = withStore.extensions.getStyle(style_id);
    let params = {
      height: style.height,
      width: style.width,
      render_url: encodeURI(config.root_uri + '/render_tmp/' + doc_id),
    };
    return apiCall(url, 'GET', null, params, 'blob');
  },
  fetchThumbnail: (doc_id) => {
    const url = config.api.doc.api_uri + '/bulletins/' + doc_id + '/tn';
    let params = {
      render_url: encodeURI(config.root_uri + '/render/' + doc_id),
    };
    return apiCall(url, 'GET', null, params);
  },
  updateThumbnail: (doc_id) => {
    const url = config.api.doc.api_uri + '/bulletins/' + doc_id + '/tn';
    let data = {
      render_url: encodeURI(config.root_uri + '/render/' + doc_id),
    };
    return apiCall(url, 'PUT', data);
  },

  createDocument: (new_document_for_server) => {
    const url = config.api.doc.api_uri + '/bulletins';
    let style = withStore.extensions.getStyle(
      new_document_for_server.settings.style_id,
    );
    if (!style) {
      throw new Error(
        `The default style for new documents is not defined for the org.
        When developing the default styles in config needs to match the selected service.`,
      );
    }
    let altered_doc = Object.assign({}, new_document_for_server);
    altered_doc = doc_helpers.packDocumentSettings(altered_doc);
    let packed_doc = {
      data: altered_doc,
      width: style.width,
      height: style.height,
    };
    return apiCall(url, 'POST', packed_doc).then((server_doc) => {
      return doc_helpers.unpackDocumentFromServer(server_doc, config);
    });
  },
  deleteDocument: (doc_id, last_modified) => {
    const url = config.api.doc.api_uri + '/bulletins/' + doc_id;
    return apiCall(url, 'DELETE', { last_modified: last_modified });
  },
  fetchDocuments: () => {
    const url = config.api.doc.api_uri + '/bulletins';
    return apiCall(url).then((server_doc_arr) => {
      //Only keep documents we can actuall show, i.e
      //documents which have type- and styledefinitions for.
      const available_doc_types = withStore.extensions.getAvailableDocTypes();
      const available_style_ids = withStore.extensions.getAvailableStyleIds();
      return server_doc_arr
        .filter(
          (server_doc) =>
            available_doc_types.includes(server_doc.data.settings.type_id) &&
            available_style_ids.includes(server_doc.data.settings.style_id),
        )
        .map((server_doc) => {
          return doc_helpers.unpackDocumentFromServer(server_doc, config);
        });
    });
  },
  fetchDocument: (id) => {
    const url = config.api.doc.api_uri + '/bulletins/' + id;
    return apiCall(url).then((server_doc) => {
      return doc_helpers.unpackDocumentFromServer(server_doc, config);
    });
  },
  saveDocument: (doc) => {
    const url = config.api.doc.api_uri + '/bulletins/' + doc.id;
    let altered_doc = Object.assign({}, doc, {
      settings: Object.assign({}, doc.settings || {}),
      tmp_settings: Object.assign({}, doc.tmp_settings || {}),
    });
    altered_doc = doc_helpers.packDocumentForServer(altered_doc);
    return apiCall(url, 'PUT', altered_doc).then((updated_doc) => {
      return doc_helpers.unpackDocumentFromServer(updated_doc, config);
    });
  },

  createFolder: (new_folder_for_server) => {
    const url = config.api.doc.api_uri + '/folders';
    return apiCall(url, 'POST', { data: new_folder_for_server }).then(
      (server_folder) => {
        return doc_helpers.unpackFolderFromServer(server_folder);
      },
    );
  },
  deleteFolder: (folder_id, last_modified) => {
    const url = config.api.doc.api_uri + '/folders/' + folder_id;
    return apiCall(url, 'DELETE', { last_modified: last_modified });
  },
  fetchFolders: () => {
    const url = config.api.doc.api_uri + '/folders';
    return apiCall(url).then((server_folders) => {
      return server_folders.map((server_folder) => {
        return doc_helpers.unpackFolderFromServer(server_folder);
      });
    });
  },
  saveFolder: (folder) => {
    const url = config.api.doc.api_uri + '/folders/' + folder.id;
    let folder_for_server = doc_helpers.packFolderForServer(folder);
    return apiCall(url, 'PUT', folder_for_server).then((server_folder) => {
      return doc_helpers.unpackFolderFromServer(server_folder);
    });
  },
  exportDocument: (doc, png = false) => {
    let doc_in_store = withStore.extensions.getDoc(doc.id);
    let style_id = doc_in_store.hasOwnProperty('settings')
      ? doc_in_store.settings.style_id
      : null;
    style_id = style_id ? style_id : doc_in_store.settings.style_id;
    let style = withStore.extensions.getStyle(style_id);
    let params = {
      height: style.height,
      width: style.width,
      render_url: encodeURI(config.root_uri + '/render/' + doc_in_store.id),
    };
    const url = config.api.doc.api_uri + (png ? '/png' : '/pdf');
    return apiCall(url, 'GET', null, params, 'blob');
  },

  openDocument: (doc) => {
    const url = config.api.doc.api_uri + '/open/' + doc.id;
    return apiCall(url, 'POST');
  },

  closeDocument: (doc) => {
    const url = config.api.doc.api_uri + '/open/' + doc.id;
    return apiCall(url, 'DELETE');
  },

  fetchOpen: () => {
    const url = config.api.doc.api_uri + '/open';
    return apiCall(url);
  },

  fetchAllUsers: () => {
    const url = config.api.sso.api_uri + '/users';
    return apiCall(url);
  },

  fetchTablePeriods: () => {
    const url = config.api.gtfs.api_uri + '/table_periods';
    return apiCall(url);
  },
  fetchStops: (
    table_period_id = null,
    stop_ids = null,
    location_type = null,
  ) => {
    const url = config.api.gtfs.api_uri + '/stops';
    const params = {
      table_period_id: table_period_id,
      stop_ids: stop_ids,
      location_type: location_type,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchDistinctStops: () => {
    const url = config.api.gtfs.api_uri + '/all_distinct_stops';
    const params = {
      location_type: 0,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchRoutes: (table_period_id) => {
    const url = config.api.gtfs.api_uri + '/routes';
    const params = {
      table_period_id: table_period_id,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchSingleShape: (shape_id, table_period_id) => {
    const url = config.api.gtfs.api_uri + '/shapes/' + shape_id;
    const params = {
      table_period_id: table_period_id,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchMultipleShapes: (shape_ids, table_period_id) => {
    let deferreds = [];
    shape_ids.forEach((shape_id) => {
      deferreds.push(api_funcs.fetchSingleShape(shape_id, table_period_id));
    });

    return Promise.all(deferreds).then((responses) => {
      let shapes_by_id = {};
      responses.forEach((response, i) => {
        shapes_by_id[shape_ids[i]] = response.data;
      });
      return shapes_by_id;
    });
  },
  fetchStopLinks: (table_period_id) => {
    const url = config.api.gtfs.api_uri + '/stop_links';
    const params = {
      table_period_id: table_period_id,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchStopLinkShapes: (table_period_id) => {
    const url = config.api.gtfs.api_uri + '/stop_link_shapes';
    const params = {
      table_period_id: table_period_id,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchRouteStops: (table_period_id, route_id) => {
    const url = config.api.gtfs.api_uri + '/route_stops/' + route_id;
    const params = {
      table_period_id: table_period_id,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchAllRouteStops: (table_period_id) => {
    const url = config.api.gtfs.api_uri + '/route_stops';
    const params = {
      table_period_id: table_period_id,
    };
    return apiCall(url, 'GET', null, params);
  },
  fetchAllRouteDirectionStops: (table_period_id) => {
    const url = config.api.gtfs.api_uri + '/route_direction_stops';
    const params = {
      table_period_id: table_period_id,
    };

    return apiCall(url, 'GET', null, params);
  },
};

function apiCall(
  url,
  method = 'GET',
  data = null,
  params = null,
  response_type = null,
) {
  let stack = new Error().stack;
  return new Promise((resolve, reject) => {
    const jwt_token = window.sso.getJWT().getRaw();
    let req = {
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    if (data) {
      req.data = data;
    }
    if (params) {
      req.params = params;
    }
    if (response_type) {
      req.responseType = response_type;
    }
    axios(req)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        err.stack = stack;
        err.network_error = true;
        return reject(err);
      });
  });
}

let export_funcs = {};

Object.keys(api_funcs).forEach((function_name) => {
  export_funcs[function_name] = function () {
    if (config) {
      return api_funcs[function_name](...arguments);
    }
    return withConfig.get().then((cfg) => {
      config = cfg;
      return api_funcs[function_name](...arguments);
    });
  };
});

export default export_funcs;
