import React from 'react';
import withStore from 'with-store';
import styled from 'styled-components';
import MapBase from '../map_module/MapBase';
import MapOverlay from '../map_module/MapOverlay';

import BusStopIcon from '../../../img/bus_stop_icon.svg';
import BusStopHighlightIcon from '../../../img/bus_stop_icon_highlight.svg';
import SelectedMarkerBG from '../../../img/selected_marker_background.svg';

import MarkerBusStop from '../../../img/markers/marker_bus_stop.svg';
import MarkerBusStopHover from '../../../img/markers/marker_bus_stop_hover.svg';
import MarkerBusStopSelected from '../../../img/markers/marker_bus_stop_selected.svg';
import MarkerBusStopClosed from '../../../img/markers/marker_bus_stop_closed.svg';
import MarkerBusStopClosedHover from '../../../img/markers/marker_bus_stop_closed_hover.svg';
import MarkerBusStopClosedSelected from '../../../img/markers/marker_bus_stop_closed_selected.svg';
import MarkerTemporaryBusStop from '../../../img/markers/marker_temporary_bus_stop_green.svg';
import MarkerTemporaryBusStopHover from '../../../img/markers/marker_temporary_bus_stop_green_hover.svg';
import MarkerTemporaryBusStopSelected from '../../../img/markers/marker_temporary_bus_stop_green_selected.svg';
import MarkerTemporaryBusStopRed from '../../../img/markers/marker_temporary_bus_stop_red.svg';
import MarkerTemporaryBusStopRedHover from '../../../img/markers/marker_temporary_bus_stop_red_hover.svg';
import MarkerTemporaryBusStopRedSelected from '../../../img/markers/marker_temporary_bus_stop_red_selected.svg';
import MarkerTemporaryBusStopBlue from '../../../img/markers/marker_temporary_bus_stop_blue.svg';
import MarkerTemporaryBusStopBlueHover from '../../../img/markers/marker_temporary_bus_stop_blue_hover.svg';
import MarkerTemporaryBusStopBlueSelected from '../../../img/markers/marker_temporary_bus_stop_blue_selected.svg';
import MarkerTemporaryBusStopOrange from '../../../img/markers/marker_temporary_bus_stop_orange.svg';
import MarkerTemporaryBusStopOrangeHover from '../../../img/markers/marker_temporary_bus_stop_orange_hover.svg';
import MarkerTemporaryBusStopOrangeSelected from '../../../img/markers/marker_temporary_bus_stop_orange_selected.svg';
import MarkerDisruption from '../../../img/markers/marker_disruption.svg';
import MarkerDisruptionHover from '../../../img/markers/marker_disruption_hover.svg';
import MarkerDisruptionSelected from '../../../img/markers/marker_disruption_selected.svg';

import map_style from '../../../map_styling';

let icons = {
  default: null,
  bus_stop: BusStopIcon,
  bus_stop_hover: BusStopHighlightIcon,
  bus_stop_highlight: BusStopHighlightIcon,
  bubble_marker: SelectedMarkerBG,
  bubble_marker_hover: SelectedMarkerBG,
  bubble_marker_selected: SelectedMarkerBG,

  placed_bus_stop: MarkerBusStop,
  placed_bus_stop_hover: MarkerBusStopHover,
  placed_bus_stop_selected: MarkerBusStopSelected,
  placed_temporary_bus_stop: MarkerTemporaryBusStop,
  placed_temporary_bus_stop_hover: MarkerTemporaryBusStopHover,
  placed_temporary_bus_stop_selected: MarkerTemporaryBusStopSelected,
  placed_temporary_bus_stop_red: MarkerTemporaryBusStopRed,
  placed_temporary_bus_stop_red_hover: MarkerTemporaryBusStopRedHover,
  placed_temporary_bus_stop_red_selected: MarkerTemporaryBusStopRedSelected,
  placed_temporary_bus_stop_blue: MarkerTemporaryBusStopBlue,
  placed_temporary_bus_stop_blue_hover: MarkerTemporaryBusStopBlueHover,
  placed_temporary_bus_stop_blue_selected: MarkerTemporaryBusStopBlueSelected,
  placed_temporary_bus_stop_orange: MarkerTemporaryBusStopOrange,
  placed_temporary_bus_stop_orange_hover: MarkerTemporaryBusStopOrangeHover,
  placed_temporary_bus_stop_orange_selected: MarkerTemporaryBusStopOrangeSelected,
  placed_bus_stop_closed: MarkerBusStopClosed,
  placed_bus_stop_closed_hover: MarkerBusStopClosedHover,
  placed_bus_stop_closed_selected: MarkerBusStopClosedSelected,
  placed_disruption: MarkerDisruption,
  placed_disruption_hover: MarkerDisruptionHover,
  placed_disruption_selected: MarkerDisruptionSelected,
};

let polyline_icons = {
  solid: null, //This is default, without any icons along polyline.
  dashed: {
    icons: [
      {
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '0',
        repeat: '25px',
      },
    ],
    strokeOpacity: 0,
  },
  arrows: {
    icons: [
      {
        icon: {
          path:
            'M-4.0123186,6 L-4,12 L-5,9.67707954 L-3,9.67707954 L-4,12 C-4.00547493,10.2222222 -4.00958113,8.22222222 -4.0123186,6 Z M3,6 L3,0 L4,2.32292046 L2,2.32292046 L3,0 C3.00547493,1.77777778 3.00547493,3.77777778 3,6 Z',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '50px',
        repeat: '140px',
      },
    ],
    strokeOpacity: 1,
  },
  one_way: {
    icons: [
      {
        icon: {
          path:
            'M4.00983066,8.25667212 C4.00983066,8.25667212 4.00573455,5.83778142 3.99754233,1 L3,3.4 L4.99508467,3.4 L3.99754233,1 C4.00573455,5.83778142 4.00983066,8.25667212 4.00983066,8.25667212 Z',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '50px',
        repeat: '140px',
      },
    ],
    strokeOpacity: 1,
  },
  one_way_other: {
    icons: [
      {
        icon: {
          path: 'M4 7 3 5 5 5 4 7 4 0z',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '50px',
        repeat: '140px',
      },
    ],
    strokeOpacity: 1,
  },
  temporary_break: {
    icons: [
      {
        icon: {
          path: 'M-2, 5 l4,-4',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '0px',
        repeat: '18px',
      },
    ],
    strokeOpacity: 0,
  },
};

const Container = styled.div`
  ${(props) => props.styles}
`;

class Map extends React.Component {
  constructor(props) {
    super();
    this.styles = props.store_ext.getStyles(props.style_id)['Map'];

    this.map = null;

    this.big = props.store_ext.getStyle(props.style_id).big ? true : false;

    this.drawn_markers = [];
    this.drawn_polylines = [];
    this.drawn_bubbles = [];
    this.called_fit_bounds = false;
  }

  componentDidUpdate(prev_props) {
    if (prev_props.style_id !== this.props.style_id) {
      this.styles = this.props.store_ext.getStyles(this.props.style_id)['Map'];
      this.big = this.props.store_ext.getStyle(this.props.style_id).big
        ? true
        : false;
      this.forceUpdate();
    }
  }

  drawMapContent(src) {
    if (!this.map || !this.map.initialized || !this.MapOverlay) {
      return;
    }

    let map_obj_promises = [];
    if (this.props.settings.hasOwnProperty('polylines')) {
      Object.keys(this.props.settings.polylines).forEach(
        (bulletin_polyline_id) => {
          let bulletin_polyline = this.props.settings.polylines[
            bulletin_polyline_id
          ];
          map_obj_promises.push(
            this.addPolyline(Object.assign({}, bulletin_polyline)),
          );
        },
      );
    }
    if (this.props.settings.hasOwnProperty('markers')) {
      Object.keys(this.props.settings.markers).forEach((bulletin_marker_id) => {
        let bulletin_marker = this.props.settings.markers[bulletin_marker_id];
        map_obj_promises.push(
          this.addMarker(Object.assign({}, bulletin_marker)),
        );
      });
    }
    if (this.props.settings.hasOwnProperty('texts')) {
      Object.keys(this.props.settings.texts).forEach((bulletin_text_id) => {
        let bulletin_texts = this.props.settings.texts[bulletin_text_id];
        map_obj_promises.push(
          this.addBubble(Object.assign({}, bulletin_texts)),
        );
      });
    }

    Promise.all(map_obj_promises).then((map_objects) => {
      let root_el = document.getElementById('root');
      let map_loaded_el = document.createElement('div');
      map_loaded_el.setAttribute('id', 'mapLoaded');
      root_el.appendChild(map_loaded_el);
    });
  }

  addBubble(bulletin_bubble) {
    let internal_bubble = Object.assign({}, bulletin_bubble);
    let opts = {
      position: {
        lat: internal_bubble.coords[0],
        lng: internal_bubble.coords[1],
      },
    };

    this.MapOverlay.set(internal_bubble.id, {
      text: internal_bubble.text,
      coords: opts.position,
      type: 'rich',
      selected_routes: internal_bubble.selected_routes,
      big: this.big,
    });
    return Promise.resolve();
  }

  addPolyline(bulletin_polyline, save = false) {
    let internal_polyline = Object.assign(
      { initialized: false },
      bulletin_polyline,
    );
    let opts = {
      ...internal_polyline.style,
      path: this.map.helpers.arrToLatLngObj(internal_polyline.coords),
    };
    if (
      internal_polyline.hasOwnProperty('icon_id') &&
      internal_polyline.icon_id
    ) {
      opts.icons = polyline_icons[internal_polyline.icon_id].icons;
      if (opts.hasOwnProperty('strokeWeight')) {
        opts.icons[0].icon.strokeWeight = opts.strokeWeight;
      }
      opts.strokeOpacity =
        polyline_icons[internal_polyline.icon_id].strokeOpacity;
    }
    return this.map.setPolyline(internal_polyline.id, opts);
  }

  addMarker(bulletin_marker) {
    let internal_marker = Object.assign({}, bulletin_marker);
    this.drawn_markers.push('reserved');
    let opts = {
      ...internal_marker.style,
      position: {
        lat: internal_marker.coords[0],
        lng: internal_marker.coords[1],
      },
    };
    if (internal_marker.hasOwnProperty('icon_id')) {
      if (icons.hasOwnProperty(internal_marker.icon_id) === false) {
        console.error(
          'Unknown icon id ' +
            internal_marker.icon_id +
            ' for marker, using default.',
        );
      } else {
        opts.icon = {
          url: icons[internal_marker.icon_id],
          scaledSize: this.big
            ? new window.google.maps.Size(56, 56, 'px', 'px')
            : undefined,
          labelOrigin: this.big
            ? new window.google.maps.Point(16, 56)
            : new window.google.maps.Point(16, 40),
          anchor: this.big
            ? new window.google.maps.Point(28, 28)
            : new window.google.maps.Point(20, 20),
        };
      }
    }

    if (internal_marker.hasOwnProperty('flyout')) {
      if (!this.MapOverlay) {
        throw new Error(
          'Trying to add overlay before overlay ref has been set.',
        );
      }
      let coords = internal_marker.flyout.coords
        ? {
            lat: internal_marker.flyout.coords[0],
            lng: internal_marker.flyout.coords[1],
          }
        : { lat: internal_marker.coords[0], lng: internal_marker.coords[1] };
      let opts = {
        coords: coords,
        type: internal_marker.flyout.type,
        text: internal_marker.flyout.text,
        big: this.big,
        direction: internal_marker.flyout.direction,
      };
      if (internal_marker.flyout.selected_routes) {
        opts.selected_routes = internal_marker.flyout.selected_routes;
      }
      this.MapOverlay.set(internal_marker.id, opts);
    }
    return this.map.setMarker(internal_marker.id, opts);
  }

  onMapIdle() {
    window.google.maps.event.trigger(this.map.map, 'resize');
    if (this.props.settings.markings) {
      if (this.called_fit_bounds) {
        this.drawMapContent('idle');
        this.MapOverlay.onIdle();
      } else {
        this.map.map.fitBounds(this.props.settings.markings);
        this.called_fit_bounds = true;
      }
    } else {
      this.drawMapContent('idle');
    }
  }

  renderMapOverlay() {
    if (!this.map || !this.map.initialized) {
      return null;
    }
    return (
      <MapOverlay
        mounted={(ref) => {
          this.MapOverlay = ref;
          this.onMapIdle();
        }}
        map_ref={this.map}
      />
    );
  }

  render() {
    let config = this.props.store.config;
    let center = this.props.settings.hasOwnProperty('center')
      ? this.props.settings.center
      : config.default_map_center;
    let zoom = this.props.settings.hasOwnProperty('zoom')
      ? this.props.settings.zoom
      : config.default_map_zoom;

    return (
      <Container
        styles={this.styles}
        className={'fullescreen_' + this.props.fullscreen}
      >
        <div className="inner">
          {this.props.preview ? (
            ''
          ) : (
            <MapBase
              api_key={config.google.maps.api_key}
              styles={map_style}
              defaultOptions={{
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
                fullscreenControl: false,
              }}
              defaultZoom={zoom}
              defaultCenter={center}
              ref={(ref) => {
                this.map = ref;
              }}
              onMapIdle={() => {
                this.onMapIdle();
              }}
              initializedCB={() => {
                this.onMapIdle();
                this.forceUpdate();
              }}
            />
          )}
          {this.renderMapOverlay()}
        </div>
      </Container>
    );
  }
}

export default withStore(Map);
