import React from 'react';
import oh from 'output-helpers';
import ColorGridSelect from '../ColorGridSelect';
import Dropdown from '../../base/Dropdown';
import styled, { keyframes } from 'styled-components';
import Button from '../Button';
import Slider from 'rc-slider';
import Input from '../../base/Input';
import 'rc-slider/assets/index.css';
import withConfig from 'with-config';
const IconClose = require('react-icons/lib/md/close');

const anim_underline = keyframes`
    from {
        opacity: 0;
        height: 0;
    }
    to {
        height: 6px;
        opacity: 1;
    }
`;
const ToolOptions = styled.div`
  animation: ${anim_underline} 0.4s;
  box-shadow: ${(props) => props.theme.colors.shadow};
  border-radius: ${(props) => (props.stand_alone ? '8px' : '0 0 8px 8px')};
  position: fixed;
  top: 144px;
  right: 12px;
  .floating_tools_begin,
  .floating_tools_guide {
    padding: 20px;
  }
  .floating_tools_guide {
    border-radius: ${(props) => (props.stand_alone ? '8px 8px 0 0' : '0')};
    color: #fff;
    transition: all 0.4s;
    background-image: linear-gradient(
      45deg,
      ${(props) => props.theme.colors.red[1]} 0%,
      ${(props) => props.theme.colors.red[2]} 100%
    );
    .guide_title {
      letter-spacing: 1px;
      opacity: 0.7;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .guide_subtitle {
      font-size: 15px;
      opacity: 1;
    }
  }
  .floating_tools_begin {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
  }
  .user_input {
    background-color: #fff;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    display: block;
    width: 100%;
    padding: 14px 20px;
    position: relative;
    z-index: 1;
    &:last-child {
      border: none;
    }
    .dropdown {
      display: table;
    }
    .setting_label {
      color: ${(props) => props.theme.colors.dark[0]};
      font-size: 14px;
      margin-bottom: 12px;
      font-weight: bold;
    }
  }
  .floating_tool_hide {
    position: absolute;
    z-index: -1;
    width: 90%;
    margin-left: 5%;
    padding: 4px;
    cursor: pointer;
    border-radius: 0 0 8px 8px;
    text-align: center;
    background-color: ${(props) => props.theme.colors.border};
    transition: all 0.3s;
    &:hover {
      background-color: ${(props) => props.theme.colors.border};
      padding: 8px;
      svg {
        fill: ${(props) => props.theme.colors.gray[0]};
      }
    }
    svg {
      height: 20px;
      width: 20px;
      fill: ${(props) => props.theme.colors.gray[1]};
    }
  }
`;

// function DuplicateLineExists(polylines, color, type) {
//     return Object.keys(polylines).some((polyline_id) => {
//         const polyline = polylines[polyline_id];
//         return polyline.icon_id === type && polyline.style.strokeColor === color;
//     });
// }

const LegendSettings = (props) => {
  const settings = props.map_settings;
  let text_in_legend = '';
  let legend_index = -1;
  let legend_content = [];
  if (
    settings.hasOwnProperty('legend_content') &&
    Array.isArray(settings.legend_content)
  ) {
    legend_content = settings.legend_content;
    legend_index = legend_content.findIndex((content) => {
      return content.type === props.type && content.color === props.color;
    });

    if (legend_index > -1) {
      text_in_legend = legend_content[legend_index].text;
    }
  }

  return (
    <>
      <div className="setting">
        <p className="setting_label">{oh.translate('visible_in_legend')}</p>
        <div className="settings_checkbox">
          <input
            type="checkbox"
            checked={legend_index > -1}
            onChange={(e) => {
              const checked = e.target.checked;
              // console.log("delete:", legend_index > -1, !checked);
              if (legend_index > -1 && !checked) {
                const updated_legend = [...legend_content];
                updated_legend.splice(legend_index, 1);
                props.onLegendChange(updated_legend);
              } else if (checked) {
                console.log('props.config', props.config);
                const texts = props.config.legend_polyline_default_text;
                const default_text = texts.hasOwnProperty(props.type)
                  ? texts[props.type]
                  : '';
                const legend_entry = {
                  type: props.type,
                  text: default_text,
                  color: props.color,
                };
                const updated_legend = [...legend_content];
                updated_legend.push(legend_entry);
                props.onLegendChange(updated_legend);
              }
            }}
          />
        </div>
        {legend_index < 0 ? null : (
          <div className="settings_checkbox_content">
            <Input
              value={text_in_legend}
              placeholder={oh.translate('text_in_legend')}
              onChange={(e) => {
                const legend_entry = {
                  type: props.type,
                  text: e.target.value,
                  color: props.color,
                };
                const updated_legend = [...legend_content];
                updated_legend[legend_index] = legend_entry;
                props.onLegendChange(updated_legend);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const MapSettingsPanelPolyline = (props) => {
  if (!props.cutting_mode_enabled) {
    return (
      <div className="floating_item_settings_container">
        <div className={`floating_item_settings line`}>
          <div className="item_title">
            <p>
              {oh.translate('options_for')} {oh.translate('line')}
            </p>
            <div
              className="item_close"
              onClick={() => {
                props.onClose();
              }}
            >
              <IconClose />
            </div>
          </div>
          <div className="item_settings">
            <div className="setting" style={{ zIndex: 2 }}>
              <p className="setting_label">{oh.translate('line_style')}</p>
              <Dropdown
                block
                empty_message={oh.translate('no_polyline_marker_types')}
                selected={props.type}
                options={props.available_types}
                selectedOption={(item) => {
                  console.log('item_id', item);
                  props.onTypeChange(item);
                }}
              />
            </div>
            <div className="setting">
              <p className="setting_label">{oh.translate('thickness')}</p>
              <Slider
                value={props.thickness}
                min={1}
                max={16}
                onChange={(new_value) => props.onThicknessChange(new_value)}
                trackStyle={[{ backgroundColor: '#ed8a85' }]}
                handleStyle={[{ borderColor: '#bf392c' }]}
              />
            </div>
            <div className="setting">
              <p className="setting_label">{oh.translate('color')}</p>
              <ColorGridSelect
                grid_type="colors"
                value={props.color}
                onChange={(new_value) => props.onColorChange(new_value)}
                options={props.availableColors}
              />
            </div>
            <div className="setting">
              <Button
                block
                filled
                blue
                label={oh.translate('cut') + ' ' + oh.translate('line', false)}
                onClick={() => {
                  props.onSetCuttingMode();
                }}
              />
            </div>
            <LegendSettings {...props} />
            <div className="setting_divider" />
            <div className="setting">
              <Button
                block
                filled
                red
                label={oh.translate('remove')}
                onClick={() => {
                  props.onDelete();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ToolOptions stand_alone={true}>
      <div className="floating_tools_guide">
        <p className="guide_title">{oh.translate('cut_me')}</p>
        <p className="guide_subtitle">{oh.translate('cut_me')}</p>
      </div>
      <div className="floating_tools_begin">
        <Button
          filled
          block
          big
          green
          onClick={() => {
            props.onCuttingModeComplete();
          }}
          label={oh.translate('finish')}
        />
        <br />
        <Button
          filled
          block
          big
          dark
          onClick={() => {
            props.onCuttingModeCancel();
          }}
          label={oh.translate('cancel')}
        />
      </div>
    </ToolOptions>
  );
};

export default withConfig(MapSettingsPanelPolyline);
