import React from 'react';
import styled from 'styled-components';
import withStore from 'with-store';

import MarkerBusStop from '../../img/markers/marker_bus_stop.svg';
import MarkerBusStopClosed from '../../img/markers/marker_bus_stop_closed.svg';
import MarkerTemporaryBusStop from '../../img/markers/marker_temporary_bus_stop_green.svg';
import MarkerTemporaryBusStopRed from '../../img/markers/marker_temporary_bus_stop_red.svg';
import MarkerTemporaryBusStopBlue from '../../img/markers/marker_temporary_bus_stop_blue.svg';
import MarkerTemporaryBusStopOrange from '../../img/markers/marker_temporary_bus_stop_orange.svg';
import MarkerDisruption from '../../img/markers/marker_disruption.svg';
import GetPolyLineIcon from '../../img/PolylineIcons';

import { ArrowUp, ArrowDown, Remove } from '../../img/icons';

let icons = {
  placed_bus_stop: MarkerBusStop,
  placed_temporary_bus_stop: MarkerTemporaryBusStop,
  placed_temporary_bus_stop_red: MarkerTemporaryBusStopRed,
  placed_temporary_bus_stop_blue: MarkerTemporaryBusStopBlue,
  placed_temporary_bus_stop_orange: MarkerTemporaryBusStopOrange,
  placed_bus_stop_closed: MarkerBusStopClosed,
  placed_disruption: MarkerDisruption,
};

const Container = styled.div`
  ${(props) => props.legendStyles && props.legendStyles}
`;

const OrderingContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-left: 16px;
  & .innerContainer {
    border: 1px solid #ced9ee;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
  }
  & .innerContainer:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Button = styled.div`
  border-left: 1px solid #ced9ee;
  padding: 8px;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    & svg {
      fill: lightgrey;
    }
  `}
  &:hover {
    & svg {
      fill: #5d92f1;
    }
  }
  &.remove {
    background-color: ${(props) => props.theme.colors.red[1]};
    & svg {
      fill: #ffffff;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.red[3]};
    }
  }
  &:first-child {
    border-left: none;
  }
`;

// const Container = styled.div`
//   padding: 6px 8px;
//   background-color: #fff;
//   border: 1px solid #000;
//   color: #000;
//   border-radius: 8px;
//   position: absolute;
//   z-index: 1;
//   &.bottom_left {
//     bottom: 20px;
//     left: 12px;
//   }
//   &.bottom_right {
//     bottom: 20px;
//     right: 12px;
//   }
//   &.top_left {
//     top: 20px;
//     left: 12px;
//   }
//   &.top_right {
//     top: 20px;
//     right: 12px;
//   }
//   ul {
//     li {
//       cursor: pointer;
//       margin-bottom: 2px;
//       white-space: nowrap;
//       .legend_item_icon,
//       .legend_item_label {
//         display: inline-block;
//         vertical-align: middle;
//       }
//       .legend_item_icon {
//       }
//       .legend_item_label {
//         padding-left: 10px;
//         font-weight: bold;
//       }
//       &:hover {
//         opacity: 0.6;
//         .legend_item_label {
//           &:after {
//             font-weight: 400;
//             font-size: 14px;
//             content: ' (ta bort)';
//           }
//         }
//       }
//     }
//   }
//   .placeholder {
//     width: 32px;
//     height: 32px;
//     background-size: contain;
//     background-repeat: no-repeat;
//   }
// `;

const MapLegend = ({
  position = 'bottom_left',
  style_id,
  map_settings,
  store_ext,
  onRemove,
  onOrderChange,
  use_combined_style = true,
}) => {
  //position can be: "bottom_left" | "bottom_right" | "top_left" | "top_right".

  if (
    Array.isArray(map_settings.legend_content) === false ||
    map_settings.legend_content.length === 0
  ) {
    //Early out if no legend content is specified.
    return null;
  }
  const legend_styles = store_ext.getMapLegendStyles(style_id);

  const isEdit = !!onRemove || !!onOrderChange;
  const conf = withStore.getState().config;
  const use_legend_priority =
    !!conf.use_legend_priority && conf.use_legend_priority;

  if (!!conf.legend_priority_list && use_legend_priority) {
    map_settings.legend_content.sort((a, b) => {
      return (
        conf.legend_priority_list[a.type] - conf.legend_priority_list[b.type]
      );
    });
  }

  return (
    <Container
      className={'map_legend ' + position}
      legendStyles={
        use_combined_style ? legend_styles.combined : legend_styles.default
      }
    >
      <ul>
        {map_settings.legend_content.map((item, index) => {
          let key = item.type;
          if (item.color) {
            //This is a polyline item.
            key += item.color;
            return (
              <li key={key} className={isEdit && 'edit'}>
                <div className="legend_item_icon">
                  <div
                    className="placeholder"
                    style={{
                      fill: item.color,
                    }}
                  >
                    {GetPolyLineIcon(item.type)}
                  </div>
                </div>
                <div className="legend_item_label">{item.text}</div>
                {!!onOrderChange && (
                  <div>
                    <OrderingContainer>
                      <div className="innerContainer">
                        {!use_legend_priority ? (
                          <>
                            <Button
                              disabled={index < 1}
                              className="button"
                              onClick={() => onOrderChange(index, index - 1)}
                            >
                              <ArrowUp />
                            </Button>

                            <Button
                              disabled={
                                index >= map_settings.legend_content.length - 1
                              }
                              className="button"
                              onClick={() => onOrderChange(index, index + 1)}
                            >
                              <ArrowDown />
                            </Button>
                          </>
                        ) : null}
                        <Button
                          className="button remove"
                          onClick={() => onRemove && onRemove(index, item)}
                        >
                          <Remove />
                        </Button>
                      </div>
                    </OrderingContainer>
                  </div>
                )}
              </li>
            );
          }
          return (
            <li key={key} className={isEdit && 'edit'}>
              <div className="legend_item_icon">
                <div
                  className="placeholder"
                  style={{
                    backgroundImage: 'url(' + icons[item.type] + ')',
                  }}
                />
              </div>
              <div className="legend_item_label">{item.text}</div>
              {!!onOrderChange && (
                <div>
                  <OrderingContainer>
                    <div className="innerContainer">
                      {!use_legend_priority ? (
                        <>
                          <Button
                            disabled={index < 1}
                            className="button"
                            onClick={() => onOrderChange(index, index - 1)}
                          >
                            <ArrowUp />
                          </Button>

                          <Button
                            disabled={
                              index >= map_settings.legend_content.length - 1
                            }
                            className="button"
                            onClick={() => onOrderChange(index, index + 1)}
                          >
                            <ArrowDown />
                          </Button>
                        </>
                      ) : null}
                      <Button
                        className="button remove"
                        onClick={() => onRemove && onRemove(index, item)}
                      >
                        <Remove />
                      </Button>
                    </div>
                  </OrderingContainer>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default withStore(MapLegend);
