export default [
  {
    title: '2.1.0',
    date: '2021-12-14',
    new: [
      'Lagt till möjligheten att ha med en "Läs mer" url i dokumenten.',
      'Läs mer länken kan visas antingen som en vanlig länk längst ner i dokumenten eller som en QR kod, eller som bägge alternativen samtidigt.',
      'Lagt till ett nytt alternativ för titel storlekar: Extra stor titel.',
      'Lagt till alternativ för vissa kunder att ha fasta legender i kartan som automatiskt sorteras efter en prioriterings ordning.',
    ],
    fixed: [
      'Under särskilda situationer så kunde kartan krascha när man försökte klippa eller lägga till ny linje, det här felet bör inte längre inträffa.',
      'Text alternativ som inte var titlar gick inte att centrera eller höger-justera, det här är nu åtgärdat.',
      'Typsnittet för vissa kunder var inte korrekt utifrån vad som hade specificerats, det här är nu åtgärdat.',
      'Datumet för när ett dokument blev skapat i informations boxen visade istället när dokumentet senast blev redigerat, det här är nu åtgärdat.',
    ],
  },
  {
    title: '2.0.14',
    date: '2020-02-04',
    new: ['Möjligheten att ändra ordning på legenden.'],
    fixed: [
      'Tog bort möjligheten att ta bort rader i legenden i den renderade vyn.',
    ],
  },
  {
    title: '2.0.5',
    date: '2019-05-17',
    fixed: [
      'Fixat problem med storlek och position av förhandsgranskningsvyn.',
    ],
  },
  {
    title: '2.0.3',
    date: '2019-05-06',
    new: ['Anslagssök visar nu resultat från arkivet.'],
    fixed: [
      'Fixade fall där kartan inte kunde interageras med när högerpanelen var öppen.',
      'Tog bort oanvända H1 och H2 textval i brödtext.',
      'Textbubblor på kartan ska nu behålla sin position efter omladdning av dokumentet.',
    ],
  },
  {
    title: '2.0.0',
    date: '2019-05-02',
    new: [
      'Implementerat export i PNG format.',
      'Möjlighet att justera textstorlek.',
    ],
  },
  {
    title: '1.2.0',
    date: '2019-03-26',
    new: ['Implementerat sökfunktionalitet på startsidan.'],
  },
  {
    title: '1.1.0',
    date: '2019-03-21',
    new: [
      'Möjlighet att placera kartans legend i valfritt hörn.',
      'Markörer och linjer kan valfritt läggas till i legenden.',
    ],
  },
  {
    title: '1.0.0',
    date: '2018-12-12',
    new: ['Release av version 1'],
  },
];
