import { a4_portrait, a3_portrait } from '../standard';
import Graphic_customer_vl from '../../img/customer_graphics_vl_logo_without_leaf.svg';

// let vl_red = 'rgb(199, 1, 40)';
let vl_red = '#F5002F';
let a4_affected_route_icon = {
  border_radius: 20,
  border_width: 2,
  font_size: 45,
  padding: 6,
  size: 80,
};
const route_icon_a3_factor = 1.6;
let a3_affected_route_icon = {
  border_radius: a4_affected_route_icon.border_radius * route_icon_a3_factor,
  border_width: a4_affected_route_icon.border_width * route_icon_a3_factor,
  font_size: a4_affected_route_icon.font_size * route_icon_a3_factor,
  padding: a4_affected_route_icon.padding * route_icon_a3_factor,
  size: a4_affected_route_icon.size * route_icon_a3_factor,
};

export default {
  default: {
    OrganizationProfile: ``,
    RichText: `
      font-family: HelveticaMedium;
      &.type_title {
        font-family: HelveticaBold;
      }
    `,

    RouteIcons: `
      background-color: ${vl_red};
      font-family: 'helvetica-neue-lt-std-75-bold';
      display: flex;
      gap: ${a4_affected_route_icon.padding * 2}px;
      padding-bottom: ${a4_affected_route_icon.padding * 2}px;
      padding-top: ${a4_affected_route_icon.padding * 2}px;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      & .route_icon > .border {
        border-radius: ${a4_affected_route_icon.border_radius / 2}px;
      }
      & .circle_icon > .border {
        border-radius: 1000px;
      }
      & .square_icon > .border {
        border-radius: 0px;
      }
      & .route_icon {
        padding: ${a4_affected_route_icon.padding}px;
        font-size: ${a4_affected_route_icon.font_size}px;
        border-radius: ${a4_affected_route_icon.border_radius}px;
        min-width: 73px;
        & .border {
          border-width: ${a4_affected_route_icon.border_width}px;
          padding: inherit;
        }
        &.circle_icon {
          border-radius: 1000px;
        }
        &.square_icon {
          border-radius: 0px;
        }
      }
    `,
    Text: `&.vl_default_v2, &.vl_default_a3_v2, &.vl_default_a3_large_map, &.vl_default_a3_medium_map, &.vl_default_a3_small_map, &.vl_map_with_title_a3 {
      white-space: pre-wrap;
      font-family: Helvetica;
      font-weight: 400;
      & .weight_bold {
        font-weight: 800;
      }
      & .align_left {
        text-align: left;
      }
      & .align_right {
        text-align: right;
      }
      & .align_center {
        text-align: center;
      }
      & .italic {
        font-style: italic;
      }
      & .text_small, & .text_medium, & .text_large {
        position: relative;
        margin: 0;
        font-size: 24px;
        padding: 42px;
        padding-top: 10px;
        padding-bottom: 15px;
        z-index: 2;
      }
      & .text_medium {
        font-size: 32px;
      }
      & .text_large {
        font-size: 40px;
      }
      & .title_small, & .title_medium, & .title_large, & .title_xlarge {
        font-family: Helvetica, HelveticaBold;
        font-weight: 800;
        background-color: ${vl_red};
        color: #fff;
        font-size: 168px;
        font-weight: bold;
        padding: 0 0 50px;
        width: 100%;
        margin: 0;
        z-index: 4;
        position: relative;
      }
      & .title_medium {
        font-size: 180px;
      }
      & .title_large {
        font-size: 200px;
      }
      & .title_xlarge {
        font-size: 300px;
      }
      & .subtitle {
        background-color: ${vl_red};
        color: #fff;
        font-size: 48px;
        line-height: 54px;
        padding: 0 0 50px;
        width: 100%;
        margin: 0;
        z-index: 4;
        position: relative;
      }
      & .subtitle_large {
        background-color: ${vl_red};
        color: #fff;
        font-size: 115px;
        line-height: 125px;
        padding: 0 0 50px;
        width: 100%;
        margin: 0;
        z-index: 4;
        position: relative;
      }
      & .subtitle_large {
        font-size: calc(48px*2.41);
        line-height: calc(49px*2.41);
        padding: 0px 0px calc(45px*1.41);
      }
    }`,
    QR: `
      position: absolute;
      display: flex;
      bottom: 92px;
      padding-left: 8px;
      z-index: 1;
      gap: 10px;
      align-items: end;
      & .qr_text {
        font-family: HelveticaBold;
        color: black;
        font-size: 24px;
      }
    `,
  },
  templates: [
    {
      id: 'vl_default',
      name: 'VL vit A4',
      types: ['redirect', 'text_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 40px;
            bottom: 30px;
            width: 275px;
            height: 275px;
            z-index: 3;
          }
          .layer_2 {
            position: absolute;
            bottom: 60px;
            left: 60px;
            right: 60px;
            display: block;
            height: 524px;
            background-color: #fff;
            border-radius: 0 0 335px 0;
            z-index: 1;
          }
          .layer_3 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: 60px solid ${vl_red};
          }
        `,
        document_specification: `
          background-color: ${vl_red};
          width: 1240px;
          height: 1754px;
          padding: 60px;
          position: relative;
        `,
        Map: `
          height: 500px;
          position: relative;
          div.inner {
            height: 500px;
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        RichText: `
          &.type_title {
            background-color: ${vl_red};
            color: #fff;
            font-size: 180px;
            font-weight: bold;
            padding: 0 0 50px;
            width: 100%;
            margin: 0;
            h2 {
              font-size: 60px;
              font-weight: bold;
            }
          }
          &.type_body {
            position: relative;
            text-align: left;
            margin: 0;
            font-size: 46px;
            padding: 42px;
          }
        `,
        QR: `
          bottom: 63px;
          padding-left: 8px;
          gap: 10px;
          & .qr_text {
            font-size: 20px;
          }
        `,
      },
    },
    {
      id: 'vl_default_a3_medium_map',
      name: 'VL A3 Medium karta',
      types: ['redirect_v2'],
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 1.75,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: calc(40px*1.41);
            bottom: calc(30px*1.41);
            width: calc(275px*1.41);
            height: calc(275px*1.41);
            z-index: 3;
          }
          .layer_2{
            position: absolute;
            bottom: calc(60px*1.41);
            left: calc(60px*1.41);
            right: calc(60px*1.41);
            display: block;
            height: calc(4000px*1.41);
            background-color: #fff;
            border-radius: 0 0 calc(335px*1.41) 0;
            z-index: 0;
          }
          .layer_3{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: calc(61px*1.41) solid ${vl_red};
          }
        `,
        RouteIcons: `
          background-color: ${vl_red};
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        document_specification: `
          background-color: ${vl_red};
          width: 1754px;
          height: 2480px;
          padding: calc(60px*1.41);
          position: relative;
        `,
        Map: `
          height: calc(500px*1.82);
          position: relative;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        Text: `
          &.vl_default_a3_medium_map {
            margin-top: -1px;
            & .text_small, & .text_medium, & .text_large {
              font-size: calc(24px*1.41);
            }
            & .text_medium {
              font-size: calc(32px*1.41);
            }
            & .text_large {
              font-size: calc(40px*1.41);
            }
            & .title_small, & .title_medium, & .title_large, & .title_xlarge {
              font-size: calc(168px*1.41);
              padding: 0 0 calc(50px*1.41);
            }
            & .title_medium {
              font-size: calc(180px*1.41);
            }
            & .title_large {
              font-size: calc(200px*1.41);
            }
            & .title_xlarge {
              font-size: calc(300px*1.41);
            }
            & .subtitle {
              font-size: calc(48px*1.41);
              line-height: calc(49px*1.41);
              padding: 0px 0px calc(45px*1.41);
            }
            & .subtitle_large {
              font-size: calc(48px*2.41);
              line-height: calc(49px*2.41);
              padding: 0px 0px calc(45px*1.41);
            }
          }
        `,
      },
    },
    {
      id: 'vl_default_a3_v2',
      name: 'VL A3',
      types: ['text_only_v2'],
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: calc(40px*1.41);
            bottom: calc(30px*1.41);
            width: calc(275px*1.41);
            height: calc(275px*1.41);
            z-index: 3;
          }
          .layer_2{
            position: absolute;
            bottom: calc(60px*1.41);
            left: calc(60px*1.41);
            right: calc(60px*1.41);
            display: block;
            height: calc(4000px*1.41);
            background-color: #fff;
            border-radius: 0 0 calc(335px*1.41) 0;
            z-index: 0;
          }
          .layer_3{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: calc(61px*1.41) solid ${vl_red};
          }
        `,
        RouteIcons: `
          background-color: ${vl_red};
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.circle_icon {
              border-radius: 1000px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        document_specification: `
          background-color: ${vl_red};
          width: 1754px;
          height: 2480px;
          padding: calc(60px*1.41);
          position: relative;
        `,
        Map: `
          height: calc(500px*1.41);
          position: relative;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        Text: `
          &.vl_default_a3_v2 {
            margin-top: -1px;
            & .text_small, & .text_medium, & .text_large {
              font-size: calc(24px*1.41);
            }
            & .text_medium {
              font-size: calc(32px*1.41);
            }
            & .text_large {
              font-size: calc(40px*1.41);
            }
            & .title_small, & .title_medium, & .title_large, & .title_xlarge {
              font-size: calc(168px*1.41);
              padding: 0 0 calc(50px*1.41);
            }
            & .title_medium {
              font-size: calc(180px*1.41);
            }
            & .title_large {
              font-size: calc(200px*1.41);
            }
            & .title_xlarge {
              font-size: calc(300px*1.41);
            }
            & .subtitle {
              font-size: calc(48px*1.41);
              line-height: calc(49px*1.41);
              padding: 0px 0px calc(45px*1.41);
            }
            & .subtitle_large {
              font-size: calc(48px*2.41);
              line-height: calc(49px*2.41);
              padding: 0px 0px calc(45px*1.41);
            }
          }
        `,
      },
    },
    {
      id: 'vl_default_a3_large_map',
      name: 'VL A3 Stor karta',
      types: ['redirect_v2'],
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 1.31,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: calc(40px*1.41);
            bottom: calc(30px*1.41);
            width: calc(275px*1.41);
            height: calc(275px*1.41);
            z-index: 3;
          }
          .layer_2{
            position: absolute;
            bottom: calc(60px*1.41);
            left: calc(60px*1.41);
            right: calc(60px*1.41);
            display: block;
            height: calc(4000px*1.41);
            background-color: #fff;
            border-radius: 0 0 calc(335px*1.41) 0;
            z-index: 0;
          }
          .layer_3{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: calc(61px*1.41) solid ${vl_red};
          }
        `,
        RouteIcons: `
          background-color: ${vl_red};
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        document_specification: `
          background-color: ${vl_red};
          width: 1754px;
          height: 2480px;
          padding: calc(60px*1.41);
          position: relative;
        `,
        Map: `
          height: calc(500px*2.41);
          position: relative;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        Text: `
          &.vl_default_a3_large_map {
            margin-top: -1px;
            & .text_small, & .text_medium, & .text_large {
              font-size: calc(24px*1.41);
            }
            & .text_medium {
              font-size: calc(32px*1.41);
            }
            & .text_large {
              font-size: calc(40px*1.41);
            }
            & .title_small, & .title_medium, & .title_large, & .title_xlarge {
              font-size: calc(168px*1.41);
              padding: 0 0 calc(50px*1.41);
            }
            & .title_medium {
              font-size: calc(180px*1.41);
            }
            & .title_large {
              font-size: calc(200px*1.41);
            }
            & .title_xlarge {
              font-size: calc(300px*1.41);
            }
            & .subtitle {
              font-size: calc(48px*1.41);
              line-height: calc(49px*1.41);
              padding: 0px 0px calc(45px*1.41);
            }
            & .subtitle_large {
              font-size: calc(48px*2.41);
              line-height: calc(49px*2.41);
              padding: 0px 0px calc(45px*1.41);
            }
          }
        `,
      },
    },
    {
      id: 'vl_default_a3_small_map',
      name: 'VL A3 Liten karta',
      types: ['redirect_v2'],
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 2.25,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: calc(40px*1.41);
            bottom: calc(30px*1.41);
            width: calc(275px*1.41);
            height: calc(275px*1.41);
            z-index: 3;
          }
          .layer_2{
            position: absolute;
            bottom: calc(60px*1.41);
            left: calc(60px*1.41);
            right: calc(60px*1.41);
            display: block;
            height: calc(4000px*1.41);
            background-color: #fff;
            border-radius: 0 0 calc(335px*1.41) 0;
            z-index: 0;
          }
          .layer_3{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: calc(61px*1.41) solid ${vl_red};
          }
        `,
        RouteIcons: `
          background-color: ${vl_red};
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        document_specification: `
          background-color: ${vl_red};
          width: 1754px;
          height: 2480px;
          padding: calc(60px*1.41);
          position: relative;
        `,
        Map: `
          height: calc(500px*1.41);
          position: relative;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        Text: `
          &.vl_default_a3_small_map {
            margin-top: -1px;
            & .text_small, & .text_medium, & .text_large {
              font-size: calc(24px*1.41);
            }
            & .text_medium {
              font-size: calc(32px*1.41);
            }
            & .text_large {
              font-size: calc(40px*1.41);
            }
            & .title_small, & .title_medium, & .title_large, & .title_xlarge {
              font-size: calc(168px*1.41);
              padding: 0 0 calc(50px*1.41);
            }
            & .title_medium {
              font-size: calc(180px*1.41);
            }
            & .title_large {
              font-size: calc(200px*1.41);
            }
            & .title_xlarge {
              font-size: calc(300px*1.41);
            }
            & .subtitle {
              font-size: calc(48px*1.41);
              line-height: calc(49px*1.41);
              padding: 0px 0px calc(45px*1.41);
            }
            & .subtitle_large {
              font-size: calc(48px*2.41);
              line-height: calc(49px*2.41);
              padding: 0px 0px calc(45px*1.41);
            }
          }
        `,
      },
    },

    {
      id: 'vl_default_v2',
      name: 'VL A4',
      types: ['redirect_v2', 'text_only_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      map_aspect: 2.24,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 40px;
            bottom: 30px;
            width: 275px;
            height: 275px;
            z-index: 3;
          }
          .layer_2{
            position: absolute;
            bottom: 60px;
            left: 60px;
            right: 60px;
            display: block;
            height: 4000px;
            background-color: #fff;
            border-radius: 0 0 335px 0;
            z-index: 0;
          }
          .layer_3{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: 60px solid ${vl_red};
          }
        `,
        document_specification: `
          background-color: ${vl_red};
          width: 1240px;
          height: 1754px;
          padding: 60px;
          position: relative;
        `,
        Map: `
          height: 500px;
          position: relative;
          div.inner {
            height: 500px;
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        QR: `
          bottom: 63px;
          padding-left: 8px;
          gap: 10px;
          & .qr_text {
            font-size: 20px;
          }
        `,
      },
    },
    {
      id: 'vl_map_with_title_a3',
      name: 'Helkarta A3',
      types: ['map_with_title'],
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 0.71,
      styles: {
        OrganizationProfile: `
          position: unset;
          .layer_1 {
            background-image: url(${Graphic_customer_vl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: calc(40px*1.41);
            bottom: calc(30px*1.41);
            width: calc(275px*1.41);
            height: calc(275px*1.41);
            z-index: 3;
          }
          .layer_2{
            position: absolute;
            bottom: calc(60px*1.41);
            left: calc(60px*1.41);
            right: calc(60px*1.41);
            display: block;
            height: calc(4000px*1.41);
            background-color: #fff;
            border-radius: 0 0 calc(335px*1.41) 0;
            z-index: 0;
          }
          .layer_3{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: calc(61px*1.41) solid ${vl_red};
          }
        `,
        document_specification: `height: ${a3_portrait.height}px;
          width: ${a3_portrait.width}px;
          background-color: ${vl_red};
          padding: calc(60px*1.41);
          position: relative;
        `,
        Map: `
          height: ${a3_portrait.height - 750}px;
          position: relative;
          div.inner {
            height: 2480px;
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
        MapLegend: `
        padding: 8px 11px;
        border: 2px solid #000;
        border-radius: 11px;
        font-size: 22px;
        &.bottom_left {
          bottom: 28px;
          left: 17px;
        }
        &.bottom_right {
          bottom: 28px;
          right: 17px;
        }
        &.top_left {
          top: 28px;
          left: 17px;
        }
        &.top_right {
          top: 28px;
          right: 17px;
        }
        ul {
          li {
            margin-bottom: 3px;
            .legend_item_label {
              padding-left: 14px;
            }
            &:hover {
              .legend_item_label {
                &:after {
                  font-size: 19px;
                }
              }
            }
          }
        }
        .placeholder {
          width: 45px;
          height: 45px;
          & svg {
            width: 45px;
            height: 45px;
          }
        }
        `,
        Text: `
          &.vl_map_with_title_a3 {
            margin-top: -1px;
            & .title_small, & .title_medium, & .title_large, & .title_xlarge {
              font-size: calc(80px*1.41);
              padding: 0 0 calc(50px*1.41);
            }
            & .title_medium {
              font-size: calc(120px*1.41);
            }
            & .title_large {
              font-size: calc(200px*1.41);
            }
            & .title_xlarge {
              font-size: calc(300px*1.41);
            }
          }
        `,
      },
    },
  ],
};
