import * as React from 'react';

const RouteIcon = ({ label, foreground, background, shape }) => {
  return (
    <div
      className={'route_icon ' + shape + '_icon'}
      style={{
        position: 'relative',
        height: '100%',
        backgroundColor: background,
        color: foreground,
      }}
    >
      <div
        className="border"
        style={{
          width: '100%',
          height: '100%',
          borderColor: foreground,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderStyle: 'solid',
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default RouteIcon;
