import React from 'react';
import styled from 'styled-components';
import withStore from 'with-store';
import { QRCode } from 'react-qrcode-logo';
import oh from 'output-helpers';

const options = {
  ecLevel: 'M', //The error correction level of the QR Code
  enableCORS: false, //Enable crossorigin attribute
  size: 200, //The size of the QR Code
  quietZone: 0, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
  bgColor: 'transparent', //Background color
  fgColor: '#000000', //Foreground color
  logoImage: '',
  logoWidth: 20,
  logoHeight: 20,
  logoOpacity: 1,
  qrStyle: 'squares', //Style of the QR Code modules - dots or squares
};

const Container = styled.div`
  ${(props) => props.styles}
`;

class QrIcon extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    if (
      this.props.url === undefined ||
      this.props.display_option === undefined ||
      this.props.display_option === 'none'
    ) {
      return null;
    }
    const styles = this.props.store_ext.getStyles(this.props.style_id)['QR'];

    const showQr =
      this.props.display_option === 'qr' ||
      this.props.display_option === 'both';
    const showLink =
      this.props.display_option === 'link' ||
      this.props.display_option === 'both';

    return (
      <Container className="qr_container" styles={styles}>
        {showQr ? (
          <div id="QR_CODE" className="qr_code">
            <QRCode value={this.props.url} {...options} />
          </div>
        ) : null}

        {showLink ? (
          <div id="read_more_link" className="qr_text">
            <span style={{ whiteSpace: 'pre-line' }}>
              {this.props.url === this.props.store.config.default_qr_link_url
                ? this.props.store.config.default_qr_link_text
                : `${oh.translate('read_more_text')} ${this.props.url}.`}
            </span>
          </div>
        ) : null}
      </Container>
    );
  }
}

export default withStore(QrIcon);
