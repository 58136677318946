import React from 'react';
import styled, { keyframes } from 'styled-components';
import withStore from 'with-store';
import oh from 'output-helpers';

import FloatingSearch from '../base/FloatingSearch';
import FloatingTools from '../base/FloatingTools';
//import FloatingItemSettings from '../base/FloatingItemSettings';
import MapLegend from '../base/MapLegend';
import MapBase from '../base/map_module/MapBase';
import FAQ from '../base/FAQ';

import MapSettingsPanelMarker from '../base/map_module/MapSettingsPanelMarker';
import MapSettingsPanelPolyline from '../base/map_module/MapSettingsPanelPolyline';
import MapSettingsPanelText from '../base/map_module/MapSettingsPanelText';

import Dropdown from '../base/Dropdown';
import MapOverlay from '../base/map_module/MapOverlay';
import * as map_tool_definitions from '../../map_tool_definitions.js';

import BusStopIcon from '../../img/bus_stop_icon.svg';
import BusStopHighlightIcon from '../../img/bus_stop_icon_highlight.svg';
import MarkerBubble from '../../img/markers/marker_bubble_drag.svg';
import MarkerBubbleHover from '../../img/markers/marker_bubble_drag_hover.svg';
import MarkerBubbleSelected from '../../img/markers/marker_bubble_drag_selected.svg';
import CheckboxCheck from '../../img/checkbox_check.svg';
// import DisabledBusStopIcon from '../../img/disabled_bus_stop_icon.svg';
// import TextBubbleAnchorIcon from '../../img/text_bubble_anchor.svg';

import MarkerBusStop from '../../img/markers/marker_bus_stop.svg';
import MarkerBusStopHover from '../../img/markers/marker_bus_stop_hover.svg';
import MarkerBusStopSelected from '../../img/markers/marker_bus_stop_selected.svg';

import MarkerBusStopClosed from '../../img/markers/marker_bus_stop_closed.svg';
import MarkerBusStopClosedHover from '../../img/markers/marker_bus_stop_closed_hover.svg';
import MarkerBusStopClosedSelected from '../../img/markers/marker_bus_stop_closed_selected.svg';

import MarkerTemporaryBusStop from '../../img/markers/marker_temporary_bus_stop_green.svg';
import MarkerTemporaryBusStopHover from '../../img/markers/marker_temporary_bus_stop_green_hover.svg';
import MarkerTemporaryBusStopSelected from '../../img/markers/marker_temporary_bus_stop_green_selected.svg';
import MarkerTemporaryBusStopRed from '../../img/markers/marker_temporary_bus_stop_red.svg';
import MarkerTemporaryBusStopRedHover from '../../img/markers/marker_temporary_bus_stop_red_hover.svg';
import MarkerTemporaryBusStopRedSelected from '../../img/markers/marker_temporary_bus_stop_red_selected.svg';
import MarkerTemporaryBusStopBlue from '../../img/markers/marker_temporary_bus_stop_blue.svg';
import MarkerTemporaryBusStopBlueHover from '../../img/markers/marker_temporary_bus_stop_blue_hover.svg';
import MarkerTemporaryBusStopBlueSelected from '../../img/markers/marker_temporary_bus_stop_blue_selected.svg';
import MarkerTemporaryBusStopOrange from '../../img/markers/marker_temporary_bus_stop_orange.svg';
import MarkerTemporaryBusStopOrangeHover from '../../img/markers/marker_temporary_bus_stop_orange_hover.svg';
import MarkerTemporaryBusStopOrangeSelected from '../../img/markers/marker_temporary_bus_stop_orange_selected.svg';

import MarkerDisruption from '../../img/markers/marker_disruption.svg';
import MarkerDisruptionHover from '../../img/markers/marker_disruption_hover.svg';
import MarkerDisruptionSelected from '../../img/markers/marker_disruption_selected.svg';

import map_style from '../../map_styling';
import * as gtfs_helpers from '../../helpers/gtfs_helpers';

const IconClose = require('react-icons/lib/md/close');
const Z_INDEX_STOPS = 999;
const Z_INDEX_POLYLINE = 1001;
const Z_INDEX_MARKER = 1002;
const Z_INDEX_BUBBLE = 1003;

const Z_INDEX_STOPS_HOVER = 1101;
const Z_INDEX_POLYLINE_HOVER = 1101;
const Z_INDEX_MARKER_HOVER = 1102;
const Z_INDEX_BUBBLE_HOVER = 1103;

const Z_INDEX_BUS_ROUTE_PREVIEW = 1200;

let icons = {
  default: null,
  bus_stop: BusStopIcon,
  bus_stop_hover: BusStopHighlightIcon,
  bus_stop_highlight: BusStopHighlightIcon,
  bubble_marker: MarkerBubble,
  bubble_marker_hover: MarkerBubbleHover,
  bubble_marker_selected: MarkerBubbleSelected,

  placed_bus_stop: MarkerBusStop,
  placed_bus_stop_hover: MarkerBusStopHover,
  placed_bus_stop_selected: MarkerBusStopSelected,
  placed_temporary_bus_stop: MarkerTemporaryBusStop,
  placed_temporary_bus_stop_hover: MarkerTemporaryBusStopHover,
  placed_temporary_bus_stop_selected: MarkerTemporaryBusStopSelected,
  placed_temporary_bus_stop_red: MarkerTemporaryBusStopRed,
  placed_temporary_bus_stop_red_hover: MarkerTemporaryBusStopRedHover,
  placed_temporary_bus_stop_red_selected: MarkerTemporaryBusStopRedSelected,
  placed_temporary_bus_stop_blue: MarkerTemporaryBusStopBlue,
  placed_temporary_bus_stop_blue_hover: MarkerTemporaryBusStopBlueHover,
  placed_temporary_bus_stop_blue_selected: MarkerTemporaryBusStopBlueSelected,
  placed_temporary_bus_stop_orange: MarkerTemporaryBusStopOrange,
  placed_temporary_bus_stop_orange_hover: MarkerTemporaryBusStopOrangeHover,
  placed_temporary_bus_stop_orange_selected: MarkerTemporaryBusStopOrangeSelected,
  placed_bus_stop_closed: MarkerBusStopClosed,
  placed_bus_stop_closed_hover: MarkerBusStopClosedHover,
  placed_bus_stop_closed_selected: MarkerBusStopClosedSelected,
  placed_disruption: MarkerDisruption,
  placed_disruption_hover: MarkerDisruptionHover,
  placed_disruption_selected: MarkerDisruptionSelected,
};

let selectable_marker_types = [
  'placed_bus_stop',
  'placed_temporary_bus_stop',
  'placed_temporary_bus_stop_red',
  'placed_temporary_bus_stop_blue',
  'placed_temporary_bus_stop_orange',
  'placed_disruption',
  'placed_bus_stop_closed',
];

let polyline_icons = {
  solid: null, //This is default, without any icons along polyline.
  dashed: {
    icons: [
      {
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '0',
        repeat: '25px',
      },
    ],
    strokeOpacity: 0,
  },
  arrows: {
    icons: [
      {
        icon: {
          path:
            'M-4.0123186,6 L-4,12 L-5,9.67707954 L-3,9.67707954 L-4,12 C-4.00547493,10.2222222 -4.00958113,8.22222222 -4.0123186,6 Z M3,6 L3,0 L4,2.32292046 L2,2.32292046 L3,0 C3.00547493,1.77777778 3.00547493,3.77777778 3,6 Z',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '50px',
        repeat: '140px',
      },
    ],
    strokeOpacity: 1,
  },
  one_way: {
    icons: [
      {
        icon: {
          path:
            'M4.00983066,8.25667212 C4.00983066,8.25667212 4.00573455,5.83778142 3.99754233,1 L3,3.4 L4.99508467,3.4 L3.99754233,1 C4.00573455,5.83778142 4.00983066,8.25667212 4.00983066,8.25667212 Z',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '50px',
        repeat: '140px',
      },
    ],
    strokeOpacity: 1,
  },
  one_way_other: {
    icons: [
      {
        icon: {
          path: 'M4 7 3 5 5 5 4 7 4 0z',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '50px',
        repeat: '140px',
      },
    ],
    strokeOpacity: 1,
  },
  temporary_break: {
    icons: [
      {
        icon: {
          path: 'M-2, 5 l4,-4',
          strokeOpacity: 1,
          strokeWeight: 5,
          scale: 4,
        },
        offset: '0px',
        repeat: '18px',
      },
    ],
    strokeOpacity: 0,
  },
};

const screen_border = '0';

const anim_item_settings = keyframes`
        from {
            opacity: 0;
            transform: translateY(-20px);
            z-index: 999999;
        }
        to {
            opacity: 1;
            z-index: 999999;
        }
    `;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(219, 228, 173);
  padding: 20px;
  overflow: hidden;
  .floating_search {
    top: ${screen_border};
    left: ${screen_border};
  }
  .floating_tools {
    top: ${screen_border};
    right: ${screen_border};
  }
  .map_legend {
    bottom: 54px;
    left: 5px;
  }
  .edit_map_close {
    cursor: pointer;
    background-color: rgb(255, 61, 0);
    position: absolute;
    bottom: 24px;
    right: 18px;
    border-radius: 50%;
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 10px;
    &:hover {
      background-color: rgb(194, 46, 0);
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .floating_item_settings_container {
    animation: ${anim_item_settings} 0.5s;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    position: absolute;
    overflow: auto;
    width: 420px;
    top: 130px;
    right: 0;
    max-height: calc(100vh - 130px);
  }
  .floating_item_settings {
    box-shadow: ${(props) => props.theme.colors.shadow};
    border-radius: 8px;
    text-align: left;
    width: 100%;
    z-index: 10;
    &.marker {
      .item_title {
        background-image: linear-gradient(
          45deg,
          ${(props) => props.theme.colors.brand[1]} 0%,
          ${(props) => props.theme.colors.brand[2]} 100%
        );
      }
      .setting .settings_checkbox input {
        &:hover {
          border-color: ${(props) => props.theme.colors.brand[1]};
        }
        &:checked {
          background-color: ${(props) => props.theme.colors.brand[1]};
          border-color: ${(props) => props.theme.colors.brand[0]};
        }
      }
    }
    &.line {
      .item_title {
        background-image: linear-gradient(
          45deg,
          ${(props) => props.theme.colors.red[1]} 0%,
          ${(props) => props.theme.colors.red[2]} 100%
        );
      }
      .setting .settings_checkbox input {
        &:hover {
          border-color: ${(props) => props.theme.colors.red[1]};
        }
        &:checked {
          background-color: ${(props) => props.theme.colors.red[1]};
          border-color: ${(props) => props.theme.colors.red[0]};
        }
      }
    }
    &.text {
      .item_title {
        background-image: linear-gradient(
          45deg,
          ${(props) => props.theme.colors.green[1]} 0%,
          ${(props) => props.theme.colors.green[2]} 100%
        );
      }
      .setting .settings_checkbox input {
        &:hover {
          border-color: ${(props) => props.theme.colors.green[1]};
        }
        &:checked {
          background-color: ${(props) => props.theme.colors.green[1]};
          border-color: ${(props) => props.theme.colors.green[0]};
        }
      }
    }
    .item_title {
      border-radius: 8px 0 0 0;
      background-color: gray;
      color: #fff;
      border-bottom: 2px solid ${(props) => props.theme.colors.border};
      padding: 14px 20px;
      font-size: 16px;
      font-weight: bold;
      position: sticky;
      top: 0;
      z-index: 3;
    }
    .item_close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      height: 44px;
      width: 44px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
      svg {
        height: 32px;
        width: 32px;
      }
    }
    .setting_divider {
      background-color: ${(props) => props.theme.colors.border};
      height: 5px;
      z-index: 1;
      position: relative;
    }
    .setting {
      background-color: #fff;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
      display: block;
      width: 100%;
      padding: 8px 14px;
      position: relative;
      z-index: 1;
      &:last-child {
        border-radius: 0 0 8px 8px;
        border: none;
      }
      .dropdown {
        display: table;
      }
      .setting_label {
        color: ${(props) => props.theme.colors.dark[0]};
        font-size: 12px;
        margin-bottom: 8px;
        font-weight: bold;
      }
      .settings_checkbox,
      .settings_checkbox_content {
        display: inline-block;
        vertical-align: top;
      }
      .settings_checkbox {
        width: 50px;
        input {
          appearance: none;
          background-color: #fafafa;
          border: 1px solid #d6e1f0;
          cursor: pointer;
          padding: 9px;
          border-radius: 3px;
          display: inline-block;
          position: relative;
          width: 34px;
          height: 34px;
          margin: 0;
          &:checked {
            background-image: url(${CheckboxCheck});
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
      .settings_checkbox_content {
        width: calc(100% - 50px);
      }
    }
  }
`;

const PeriodSelectorContainer = styled.div`
  box-shadow: ${(props) => props.theme.colors.shadow};
  width: 280px;
  border-radius: 0 0 8px 8px;
  background-color: white;
  position: fixed;
  left: calc(50% - 140px);
  top: 0;
  padding: 8px 12px;
  .dropdown_label {
    color: #2b344d;
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }
`;

let passing_route_styles_by_key = null;

const calculateMarkings = (map_aspect, container_size, caller) => {
  const aspect = container_size.width / container_size.height;

  const portrait = map_aspect < aspect;

  const width = portrait
    ? container_size.height * map_aspect
    : container_size.width;
  const height = portrait
    ? container_size.height
    : container_size.width / map_aspect;

  let top =
    container_size.top + (portrait ? 0 : (container_size.height - height) / 2);
  let left =
    container_size.left + (portrait ? (container_size.width - width) / 2 : 0);

  return {
    top,
    left,
    width,
    height,
  };
};

class EditMapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nothing: false,
      search_type: null,
      any_active_tool: false,
    };

    this.map = null;
    this.MapOverlay = null;
    this.map_tools = map_tool_definitions.types;
    this.FloatingToolsRef = null;
    this.container_ref = null;
    this.injectCBsInMapTools();
    this.idle_timeout = null;

    this.drawn_polylines = {};
    this.drawn_markers = {};
    this.drawn_bubbles = {};

    this.stop_refs = {};

    this.ctrl = false;
    this.content_count = 0;

    this.selected_item = null;

    this.polyline_type_options = Object.keys(polyline_icons).map(
      (polyline_type_id) => {
        return {
          id: polyline_type_id,
          name: oh.translate('line_type_' + polyline_type_id),
        };
      },
    );
    this.marker_type_options = selectable_marker_types
      .filter((marker_type_id) => {
        console.log('hide_marker_type:', props.store.config.hide_marker_type);
        console.log('marker_type_id', marker_type_id);
        return !props.store.config.hide_marker_type.includes(marker_type_id);
      })
      .map((marker_type_id) => {
        return {
          id: marker_type_id,
          name: oh.translate('marker_type_' + marker_type_id),
        };
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    this.default_polyline_hover_opts = {
      strokeWeight: props.store.config.map_default_polyline_hover_width,
      zIndex: Z_INDEX_POLYLINE_HOVER,
      icons: [
        {
          icon: {
            path: 'M0,0 l0,1',
            strokeOpacity: 1,
            strokeWeight: 10,
            scale: 6,
          },
          offset: '0px',
          repeat: '20px',
        },
      ],
    };
    this.new_polyline_opts = {
      strokeColor: props.store.config.map_default_polyline_color,
      strokeWeight: props.store.config.map_default_polyline_width,
      visible: true,
      clickable: true,
      editable: false,
      draggable: false,
      zIndex: Z_INDEX_POLYLINE,
    };
    this.default_marker_hover_opts = {
      zIndex: Z_INDEX_MARKER_HOVER,
    };
    this.new_marker_opts = {
      visible: true,
      clickable: true,
      draggable: false,
      zIndex: Z_INDEX_MARKER,
    };
    this.new_stop_marker_opts = {};
    this.stop_marker_opts = {
      zIndex: Z_INDEX_STOPS,
      clickable: true,
      draggable: false,
      visible: false,
    };
    this.stop_marker_hover_opts = {
      zIndex: Z_INDEX_STOPS_HOVER,
    };
    this.default_bubble_opts = {
      zIndex: Z_INDEX_BUBBLE,
      visible: true,
      clickable: true,
      draggable: false,
    };
    this.default_bubble_hover_opts = {
      zIndex: Z_INDEX_BUBBLE_HOVER,
    };
    this.bus_route_preview_opts = {
      zIndex: Z_INDEX_BUS_ROUTE_PREVIEW,
      visible: true,
      clickable: false,
      draggable: false,
      editable: false,
      strokeColor: props.store.config.map_bus_route_preview_color,
      strokeWeight: props.store.config.map_bus_route_preview_width,
    };
  }

  injectCBsInMapTools() {
    // BUS_STOP
    if (this.map_tools.hasOwnProperty('bus_stop')) {
      this.map_tools.bus_stop.openCB = () => {
        // Click tool icon
        this.setState({
          search_type: 'bus_stop',
          any_active_tool: true,
        });
        this.map.cancelDrawingMode('bus_stop open');
        this.map.cancelCuttingMode();
        this.showStops();
        this.deselectMapObj();
      };

      this.map_tools.bus_stop.cancelCB = () => {
        this.setState({ any_active_tool: false });
        this.hideStops();
      };
    }
    if (this.map_tools.hasOwnProperty('line')) {
      this.map_tools.line.openCB = () => {
        this.setState({
          search_type: 'bus_route',
          any_active_tool: true,
        });
        this.hideStops();
        this.deselectMapObj();
        this.map.cancelCuttingMode();
        this.map.setDrawingMode('polyline', null, (path, overlay) => {
          console.log('drawing finished callback path:', path);
          this.createPolyLine(path, true);
          this.setState({ any_active_tool: false });
          if (this.FloatingToolsRef) {
            this.FloatingToolsRef.clearSelectedTool();
          }
        });
      };

      this.map_tools.line.saveCB = () => {
        console.log('SAVE line');
        this.setState({ any_active_tool: false });
        this.map.completeDrawingMode();
      };
      this.map_tools.line.cancelCB = () => {
        console.log('CANCEL line');
        this.setState({ any_active_tool: false });
        this.map.cancelDrawingMode('line cancel');
      };
    }

    if (this.map_tools.hasOwnProperty('text')) {
      this.map_tools.text.openCB = () => {
        this.setState({ search_type: null });
        this.map.cancelDrawingMode('text open');
        this.map.cancelCuttingMode();
        this.hideStops();
        this.deselectMapObj();
        this.registerNextMapClick((e) => {
          let coords = [e.latLng.lat(), e.latLng.lng()];
          if (this.FloatingToolsRef) {
            this.FloatingToolsRef.clearSelectedTool();
          }
          this.createBubble(coords, true);
        });
      };
    }
    if (this.map_tools.hasOwnProperty('symbol')) {
      this.map_tools.symbol.openCB = () => {
        this.setState({ search_type: null });
        this.map.cancelDrawingMode('symbol open');
        this.hideStops();
        this.deselectMapObj();
        this.registerNextMapClick((e) => {
          let coords = [e.latLng.lat(), e.latLng.lng()];
          if (this.FloatingToolsRef) {
            this.FloatingToolsRef.clearSelectedTool();
          }
          this.createMarker(coords, null, true);
        });
      };
    }
  }

  componentDidMount() {
    if (!this.map) {
      return;
    }

    document.onkeydown = (e) => {
      this.keyDetector(e);
    };
    document.onkeyup = (e) => {
      this.keyDetector(e);
    };
    document.addEventListener('visibilitychange', () => {
      this.ctrl = false;
    });
    this.content_count = this.props.settings.content_count || 0;
  }

  keyDetector(e) {
    if (e.key === 'Control') {
      this.ctrl = e.type === 'keydown';
    }
  }

  registerNextMapClick(cb) {
    if (this.map && this.map.initialized) {
      if (this.nextMapClickCB && this.nextMapClickCB.hasOwnProperty('remove')) {
        this.nextMapClickCB.remove();
      }
      this.nextMapClickCB = cb;
      window.google.maps.event.addListenerOnce(this.map.map, 'click', (e) => {
        this.nextMapClickCB = null;
        cb(e);
      });
    }
  }

  selectMapObj(internal_obj, type) {
    this.deselectMapObj();
    this.map.cancelDrawingMode('select obj');
    this.map.cancelCuttingMode();
    this.selected_item = { type: type, internal_obj: internal_obj };
    switch (internal_obj.map_obj.type) {
      case 'polyline': {
        internal_obj.map_obj.gmaps_obj.setEditable(true);
        internal_obj.map_obj.gmaps_obj.setDraggable(true);
        break;
      }
      case 'marker': {
        internal_obj.map_obj.gmaps_obj.setDraggable(true);
        internal_obj.update();
        break;
      }
      case 'bubble': {
        internal_obj.map_obj.gmaps_obj.setDraggable(true);
        internal_obj.update();
        break;
      }
      default: {
        throw new Error('Unknown selected map object.');
      }
    }
    this.setState({
      any_active_tool: false,
      search_type: null,
    });

    this.FloatingToolsRef && this.FloatingToolsRef.clearSelectedTool();
  }

  deselectMapObj(deleting) {
    if (!this.selected_item) {
      return;
    }
    let internal_obj = this.selected_item.internal_obj;
    this.selected_item = null;
    switch (internal_obj.map_obj.type) {
      case 'polyline': {
        internal_obj.map_obj.gmaps_obj.setEditable(false);
        internal_obj.map_obj.gmaps_obj.setDraggable(false);
        break;
      }
      case 'marker': {
        if (!deleting) {
          internal_obj.map_obj.gmaps_obj.setDraggable(false);
          internal_obj.update();
        }
        break;
      }
      default: {
        throw new Error('Unknown selected map object.');
      }
    }
    this.forceUpdate();
  }

  createPolyLine(coords_arr, select = false, polyline_template = null) {
    if (
      !Array.isArray(coords_arr) ||
      coords_arr.length < 2 ||
      !Array.isArray(coords_arr[0])
    ) {
      console.log('Invalid input to addPolyline: ' + coords_arr);
      // Instead of hard crash, just return since this is not a serious issue.
      return;
      //throw new Error('Invalid input to addPolyline.');
    }
    let id = 'polyline_' + this.content_count;
    this.content_count++;
    let bulletin_polyline = {
      coords: coords_arr,
      style: Object.assign({}, this.new_polyline_opts),
      label: '',
      id: id,
    };
    if (polyline_template) {
      delete polyline_template['id'];
      delete polyline_template['coords'];
      bulletin_polyline = Object.assign(bulletin_polyline, polyline_template);
    }
    this.addPolyline(bulletin_polyline, true, select);
  }
  addPolyline(bulletin_polyline, save = false, select = false) {
    let internal_polyline = Object.assign(
      { initialized: false },
      bulletin_polyline,
    );
    let opts = {
      ...internal_polyline.style,
      path: this.map.helpers.arrToLatLngObj(internal_polyline.coords),
    };
    if (
      internal_polyline.hasOwnProperty('icon_id') &&
      internal_polyline.icon_id
    ) {
      opts.icons = polyline_icons[internal_polyline.icon_id].icons;
      if (opts.hasOwnProperty('strokeWeight')) {
        opts.icons[0].icon.strokeWeight = opts.strokeWeight;
      }
      opts.strokeOpacity =
        polyline_icons[internal_polyline.icon_id].strokeOpacity;
    } else {
      opts.strokeOpacity = 1;
    }
    let hover_opts = Object.assign({}, this.default_polyline_hover_opts);
    internal_polyline.updateProp = (prop_name, value) => {
      if (prop_name === 'icon_id' && !polyline_icons[value]) {
        delete internal_polyline['icon_id'];
        internal_polyline.style.icons = null;
      } else {
        internal_polyline[prop_name] = value;
      }
      internal_polyline.update();
    };
    internal_polyline.updateStyleProp = (prop_name, value) => {
      internal_polyline.style[prop_name] = value;
      internal_polyline.update();
    };
    internal_polyline.update = () => {
      if (internal_polyline.map_obj) {
        let new_opts = {
          ...internal_polyline.style,
          path: this.map.helpers.arrToLatLngObj(internal_polyline.coords),
        };
        if (
          internal_polyline.hasOwnProperty('icon_id') &&
          internal_polyline.icon_id
        ) {
          new_opts.icons = polyline_icons[internal_polyline.icon_id].icons;
          if (new_opts.hasOwnProperty('strokeWeight')) {
            new_opts.icons[0].icon.strokeWeight = new_opts.strokeWeight;
          }
          new_opts.strokeOpacity =
            polyline_icons[internal_polyline.icon_id].strokeOpacity;
        } else {
          new_opts.strokeOpacity = 1;
        }
        internal_polyline.map_obj.update(new_opts);
      }
      this.cleanAndSavePolyline(internal_polyline);
    };

    this.map
      .setPolyline(internal_polyline.id, opts, hover_opts)
      .then((map_obj) => {
        internal_polyline.map_obj = map_obj;
        this.drawn_polylines[internal_polyline.id] = internal_polyline;
        if (save) {
          this.cleanAndSavePolyline(internal_polyline);
        }

        let is_dragging = false;

        map_obj.registerEventCB('dragstart', (e) => {
          is_dragging = true;
        });
        map_obj.registerEventCB('click', (e) => {
          if (
            this.selected_item &&
            this.selected_item.internal_obj === internal_polyline
          ) {
            this.deselectMapObj();
          } else {
            this.selectMapObj(internal_polyline, 'polyline');
            if (this.ctrl) {
              this.map.setCuttingMode(internal_polyline.id, (segments) => {
                if (!segments || segments.length === 1) {
                  this.forceUpdate();
                  return;
                }
                let template = this.cleanPolyline(internal_polyline);
                segments.forEach((segment) => {
                  this.createPolyLine(
                    this.map.helpers.latLngArrayToArrayOfArrays(segment),
                    false,
                    template,
                  );
                });
                this.removePolyline(internal_polyline);
              });
            }
          }
        });
        map_obj.registerEventCB('rightclick', (e) => {
          this.removePolyline(internal_polyline);
        });

        map_obj.registerEventCB('dragend', (e) => {
          is_dragging = false;
          this.cleanAndSavePolyline(internal_polyline);
        });
        map_obj.registerEventCB('set_at', (e) => {
          if (is_dragging) {
            return;
          }
          // console.log("setat:", e);
          this.cleanAndSavePolyline(internal_polyline);
        });
        map_obj.registerEventCB('insert_at', (e) => {
          // console.log("insert:", e);
          this.cleanAndSavePolyline(internal_polyline);
        });
        map_obj.registerEventCB('remove_at', (e) => {
          // console.log("remove:", e);
          this.cleanAndSavePolyline(internal_polyline);
        });
        if (select) {
          this.selectMapObj(internal_polyline, 'polyline');
        }
      });
  }
  cleanPolyline(internal_polyline) {
    let coords = this.map.helpers.MVCArrayToArrayOfArrays(
      internal_polyline.map_obj.gmaps_obj.getPath(),
    );
    internal_polyline.coords = coords;

    let style = { ...internal_polyline.style };
    delete style['clickable'];
    delete style['draggable'];
    delete style['editable'];
    delete style['icons'];

    let bulletin_polyline = {
      label: internal_polyline.label,
      style: style,
      coords: coords,
      id: internal_polyline.id,
    };
    if (internal_polyline.hasOwnProperty('flyout')) {
      bulletin_polyline.flyout = internal_polyline.flyout;
    }
    if (internal_polyline.hasOwnProperty('icon_id')) {
      bulletin_polyline.icon_id = internal_polyline.icon_id;
    }

    return bulletin_polyline;
  }
  cleanAndSavePolyline(internal_polyline) {
    let polylines = this.props.settings.hasOwnProperty('polylines')
      ? Object.assign({}, this.props.settings.polylines)
      : {};
    let bulletin_polyline = this.cleanPolyline(internal_polyline);
    polylines[internal_polyline.id] = bulletin_polyline;
    this.saveMapChanges({ polylines: polylines });
  }
  removePolyline(internal_polyline) {
    internal_polyline.map_obj.remove();
    delete this.drawn_polylines[internal_polyline.id];
    let polylines = Object.assign({}, this.props.settings.polylines);
    delete polylines[internal_polyline.id];

    this.saveMapChanges({ polylines: polylines });
    this.deselectMapObj();
  }

  createBubble(coords, select = false) {
    if (!Array.isArray(coords) || coords.length !== 2) {
      throw new Error('Invalid input to createBubble.');
    }
    let id = 'bubble_' + this.content_count;
    this.content_count++;
    let bulletin_bubble = {
      coords: coords,
      style: Object.assign({}, this.default_bubble_opts),
      text: '',
      id: id,
    };
    this.addBubble(bulletin_bubble, true, select);
  }
  addBubble(bulletin_bubble, save = false, select = false) {
    let internal_bubble = Object.assign({}, bulletin_bubble);
    let opts = {
      ...internal_bubble.style,
      position: {
        lat: internal_bubble.coords[0],
        lng: internal_bubble.coords[1],
      },
    };
    opts.icon = {
      url: icons.bubble_marker,
      anchor: new window.google.maps.Point(20, 20),
    };
    let hover_opts = Object.assign({}, this.default_bubble_hover_opts, {
      icon: {
        url: icons.bubble_marker_hover,
        anchor: new window.google.maps.Point(20, 20),
      },
    });
    internal_bubble.updateProp = (prop_name, value) => {
      internal_bubble[prop_name] = value;
      internal_bubble.update();
    };
    internal_bubble.update = () => {
      let pos = internal_bubble.map_obj.gmaps_obj.getPosition();
      let coords = [pos.lat(), pos.lng()];
      if (internal_bubble.map_obj) {
        let new_opts = Object.assign({}, internal_bubble.map_obj.options, {
          position: { lat: coords[0], lng: coords[1] },
          draggable: false,
        });
        let new_hover_opts = Object.assign({}, hover_opts);
        let icon = icons.bubble_marker;
        let hover_icon = icons.bubble_marker_hover;
        if (
          this.selected_item &&
          this.selected_item.internal_obj === internal_bubble
        ) {
          //This bubble is selected.
          icon = icons.bubble_marker_selected;
          hover_icon = icons.bubble_marker_selected;
          new_opts.draggable = true;
        }
        new_hover_opts.icon = {
          url: hover_icon,
          anchor: new window.google.maps.Point(20, 20),
        };
        new_opts.icon = {
          url: icon,
          anchor: new window.google.maps.Point(20, 20),
        };
        internal_bubble.map_obj.update(new_opts).then(() => {
          internal_bubble.map_obj.updateHover(new_hover_opts);
        });
      }
      this.MapOverlay.set(internal_bubble.id, {
        coords: { lat: coords[0], lng: coords[1] },
        type: 'rich',
        text: internal_bubble.text,
        selected_routes: internal_bubble.selected_routes,
      });

      this.cleanAndSaveBubble(internal_bubble);
    };

    this.map.setMarker(internal_bubble.id, opts, hover_opts).then((map_obj) => {
      internal_bubble.map_obj = map_obj;
      this.drawn_bubbles[internal_bubble.id] = internal_bubble;
      if (save) {
        this.cleanAndSaveBubble(internal_bubble);
      }

      map_obj.registerEventCB('click', (e) => {
        if (
          this.selected_item &&
          this.selected_item.internal_obj === internal_bubble
        ) {
          this.deselectMapObj();
        } else {
          this.selectMapObj(internal_bubble, 'bubble');
        }
      });
      map_obj.registerEventCB('dragstart', () => {
        if (!this.MapOverlay) {
          return;
        }
        this.MapOverlay.remove(internal_bubble.id);
      });
      map_obj.registerEventCB('dragend', (e) => {
        let pos = internal_bubble.map_obj.gmaps_obj.getPosition();
        let coords = [pos.lat(), pos.lng()];
        internal_bubble.coords = coords;
        this.cleanAndSaveBubble(internal_bubble);
        if (!this.MapOverlay) {
          throw new Error('Overlay not available on dragend.');
        }
        this.MapOverlay.set(internal_bubble.id, {
          coords: { lat: pos.lat(), lng: pos.lng() },
          type: 'rich',
          text: internal_bubble.text,
          selected_routes: internal_bubble.selected_routes,
        });
      });

      if (!this.MapOverlay) {
        throw new Error(
          'Trying to add overlay before overlay ref has been set.',
        );
      }
      this.MapOverlay.set(internal_bubble.id, {
        text: internal_bubble.text,
        coords: opts.position,
        type: 'rich',
        selected_routes: internal_bubble.selected_routes,
      });
      if (select) {
        this.selectMapObj(internal_bubble, 'bubble');
      }
    });
  }
  cleanBubble(internal_bubble) {
    let pos = internal_bubble.map_obj.gmaps_obj.getPosition();
    let coords = [pos.lat(), pos.lng()];
    internal_bubble.coords = coords;
    let bulletin_bubble = {
      text: internal_bubble.text,
      coords: coords,
      id: internal_bubble.id,
      selected_routes: internal_bubble.selected_routes,
    };
    return bulletin_bubble;
  }
  cleanAndSaveBubble(internal_bubble) {
    let bulletin_bubble = this.cleanBubble(internal_bubble);
    let bubbles = this.props.settings.hasOwnProperty('texts')
      ? this.props.settings.texts
      : {};
    bubbles[internal_bubble.id] = bulletin_bubble;
    this.saveMapChanges({ texts: bubbles });
  }
  removeBubble(internal_bubble) {
    delete this.drawn_bubbles[internal_bubble.id];
    internal_bubble.map_obj.remove();
    let bubbles = Object.assign({}, this.props.settings.texts);
    delete bubbles[internal_bubble.id];
    this.MapOverlay.remove(internal_bubble.id);
    this.saveMapChanges({ texts: bubbles });
    this.deselectMapObj(true);
  }

  createMarker(coords, stop_id_reference = null, select = false) {
    if (!Array.isArray(coords) || coords.length !== 2) {
      throw new Error('Invalid input to createMarker.');
    }
    let id = 'marker_' + this.content_count;
    this.content_count++;
    let bulletin_marker = {
      coords: coords,
      style: Object.assign({}, this.new_marker_opts),
      label: '',
      id: id,
    };
    if (stop_id_reference) {
      bulletin_marker.stop_id_reference = stop_id_reference;
      bulletin_marker.icon_id = 'placed_bus_stop';
      let stop = this.props.store.gtfs.stops[stop_id_reference];
      bulletin_marker.flyout = {
        text: stop.stop_name,
        type: 'single',
      };
      bulletin_marker.style = Object.assign(
        {},
        bulletin_marker.style,
        this.new_stop_marker_opts,
        {
          icon: {
            url: icons.placed_bus_stop,
            labelOrigin: new window.google.maps.Point(16, 40),
            anchor: new window.google.maps.Point(20, 20),
          },
        },
      );
    } else {
      bulletin_marker.icon_id = 'placed_bus_stop_closed';
      bulletin_marker.style.icon = {
        url: icons.placed_bus_stop_closed,
        labelOrigin: new window.google.maps.Point(16, 40),
        anchor: new window.google.maps.Point(20, 20),
      };
    }
    this.addMarker(bulletin_marker, true, select);
  }
  addMarker(bulletin_marker, save = false, select = false) {
    let internal_marker = Object.assign({}, bulletin_marker);
    let opts = {
      ...internal_marker.style,
      position: {
        lat: internal_marker.coords[0],
        lng: internal_marker.coords[1],
      },
    };
    let hover_opts = Object.assign({}, this.default_marker_hover_opts);
    if (internal_marker.hasOwnProperty('icon_id')) {
      if (icons.hasOwnProperty(internal_marker.icon_id) === false) {
        console.error(
          'Unknown icon id ' +
            internal_marker.icon_id +
            ' for marker, using default.',
        );
      } else {
        opts.icon = {
          url: icons[internal_marker.icon_id],
          labelOrigin: new window.google.maps.Point(16, 40),
          anchor: new window.google.maps.Point(20, 20),
        };
        hover_opts.icon = {
          url: icons[internal_marker.icon_id + '_hover'],
          labelOrigin: new window.google.maps.Point(16, 40),
          anchor: new window.google.maps.Point(20, 20),
        };
      }
    }

    internal_marker.updateProp = (prop_name, value) => {
      if (prop_name === 'icon_id' && !icons[value]) {
        delete internal_marker.style['icon'];
      } else if (
        prop_name === 'flyout' &&
        value.hasOwnProperty('text') &&
        value.text.length === 0 &&
        (!value.selected_routes || value.selected_routes.length === 0)
      ) {
        delete internal_marker['flyout'];
      } else {
        internal_marker[prop_name] = value;
      }
      internal_marker.update();
    };
    internal_marker.updateStyleProp = (prop_name, value) => {
      internal_marker.style[prop_name] = value;
      internal_marker.update();
    };
    internal_marker.update = () => {
      if (internal_marker.map_obj) {
        let new_opts = {
          ...internal_marker.style,
          position: {
            lat: internal_marker.coords[0],
            lng: internal_marker.coords[1],
          },
        };
        if (
          internal_marker.hasOwnProperty('icon_id') &&
          internal_marker.icon_id
        ) {
          let icon = icons[internal_marker.icon_id];
          let hover_icon = icons[internal_marker.icon_id + '_hover'];
          if (
            this.selected_item &&
            this.selected_item.internal_obj === internal_marker
          ) {
            //This marker is selected.
            icon = icons[internal_marker.icon_id + '_selected'];
            hover_icon = icons[internal_marker.icon_id + '_selected'];
            new_opts.draggable = true;
          } else {
            new_opts.draggable = false;
          }
          new_opts.icon = {
            url: icon,
            labelOrigin: new window.google.maps.Point(16, 40),
            anchor: new window.google.maps.Point(20, 20),
          };
          let new_hover_opts = Object.assign(
            {},
            this.default_marker_hover_opts,
            {
              icon: {
                url: hover_icon,
                labelOrigin: new window.google.maps.Point(16, 40),
                anchor: new window.google.maps.Point(20, 20),
              },
            },
          );
          internal_marker.map_obj.update(new_opts).then(() => {
            internal_marker.map_obj.updateHover(new_hover_opts);
          });
        } else {
          internal_marker.map_obj.update(new_opts);
        }
      }
      if (internal_marker.hasOwnProperty('flyout')) {
        if (!this.MapOverlay) {
          throw new Error('Overlay not available on dragend.');
        }
        let coords = {
          lat: internal_marker.coords[0],
          lng: internal_marker.coords[1],
        };
        let opts = {
          coords: coords,
          type: internal_marker.flyout.type,
          text: internal_marker.flyout.text,
          direction: internal_marker.flyout.direction || 'down',
        };
        if (internal_marker.flyout.selected_routes) {
          opts.selected_routes = internal_marker.flyout.selected_routes;
        }
        this.MapOverlay.set(internal_marker.id, opts);
      } else {
        this.MapOverlay.remove(internal_marker.id);
      }
      this.cleanAndSaveMarker(internal_marker);
    };

    this.map.setMarker(internal_marker.id, opts, hover_opts).then((map_obj) => {
      internal_marker.map_obj = map_obj;
      this.drawn_markers[internal_marker.id] = internal_marker;
      if (save) {
        this.cleanAndSaveMarker(internal_marker);
      }

      map_obj.registerEventCB('click', (e) => {
        if (
          this.selected_item &&
          this.selected_item.internal_obj === internal_marker
        ) {
          this.deselectMapObj();
        } else {
          this.selectMapObj(internal_marker, 'marker');
        }
      });
      map_obj.registerEventCB('rightclick', (e) => {
        e.stop();
        this.removeMarker(internal_marker);
      });
      map_obj.registerEventCB('dragstart', () => {
        if (!this.MapOverlay) {
          return;
        }
        this.MapOverlay.remove(internal_marker.id);
      });
      map_obj.registerEventCB('dragend', (e) => {
        let pos = internal_marker.map_obj.gmaps_obj.getPosition();
        let coords = [pos.lat(), pos.lng()];
        internal_marker.coords = coords;
        this.cleanAndSaveMarker(internal_marker);
        if (internal_marker.hasOwnProperty('flyout')) {
          if (!this.MapOverlay) {
            throw new Error('Overlay not available on dragend.');
          }
          if (internal_marker.flyout.hasOwnProperty('coords') === false) {
            let opts = {
              coords: { lat: pos.lat(), lng: pos.lng() },
              type: internal_marker.flyout.type,
              text: internal_marker.flyout.text,
              direction: internal_marker.flyout.direction || 'down',
            };
            if (internal_marker.flyout.selected_routes) {
              opts.selected_routes = internal_marker.flyout.selected_routes;
            }
            this.MapOverlay.set(internal_marker.id, opts);
          }
        }
      });
      if (select) {
        this.selectMapObj(internal_marker, 'marker');
      }
    });

    if (internal_marker.hasOwnProperty('flyout')) {
      if (!this.MapOverlay) {
        throw new Error(
          'Trying to add overlay before overlay ref has been set.',
        );
      }
      let coords = internal_marker.flyout.coords
        ? {
            lat: internal_marker.flyout.coords[0],
            lng: internal_marker.flyout.coords[1],
          }
        : { lat: internal_marker.coords[0], lng: internal_marker.coords[1] };
      let opts = {
        coords: coords,
        type: internal_marker.flyout.type,
        text: internal_marker.flyout.text,
        direction: internal_marker.flyout.direction || 'down',
      };
      if (internal_marker.flyout.selected_routes) {
        opts.selected_routes = internal_marker.flyout.selected_routes;
      }
      this.MapOverlay.set(internal_marker.id, opts);
    }
  }
  cleanMarker(internal_marker) {
    let style = { ...internal_marker.style };
    let bulletin_marker = {
      label: internal_marker.style.label,
      style: style,
      coords: internal_marker.coords,
      id: internal_marker.id,
    };
    delete style['clickable'];
    delete style['draggable'];
    delete style['icon'];
    delete style['label'];

    if (internal_marker.hasOwnProperty('flyout')) {
      bulletin_marker.flyout = internal_marker.flyout;
    }
    if (internal_marker.hasOwnProperty('icon_id')) {
      bulletin_marker.icon_id = internal_marker.icon_id;
    }
    if (internal_marker.hasOwnProperty('stop_id_reference')) {
      bulletin_marker.stop_id_reference = internal_marker.stop_id_reference;
    }
    return bulletin_marker;
  }
  cleanAndSaveMarker(internal_marker) {
    let bulletin_marker = this.cleanMarker(internal_marker);
    let markers = this.props.settings.hasOwnProperty('markers')
      ? this.props.settings.markers
      : {};
    markers[internal_marker.id] = bulletin_marker;
    this.saveMapChanges({ markers: markers });
  }
  removeMarker(internal_marker) {
    delete this.drawn_markers[internal_marker.id];
    internal_marker.map_obj.remove();
    let markers = Object.assign({}, this.props.settings.markers);
    delete markers[internal_marker.id];
    this.MapOverlay.remove(internal_marker.id);

    this.saveMapChanges({ markers: markers });
    this.deselectMapObj(true);
  }

  addStops() {
    if (!this.props.store.gtfs.stops) {
      return;
    }

    Object.keys(this.props.store.gtfs.stops).forEach((stop_id, i) => {
      let stop = this.props.store.gtfs.stops[stop_id];
      let opts = Object.assign({}, this.stop_marker_opts, {
        position: { lat: stop.stop_lat, lng: stop.stop_lon },
        icon: {
          url: icons.bus_stop,
          labelOrigin: new window.google.maps.Point(16, 40),
        },
      });
      let hover_opts = Object.assign({}, this.stop_marker_hover_opts, {
        icon: {
          url: icons.bus_stop_hover,
          labelOrigin: new window.google.maps.Point(16, 40),
        },
      });
      this.map.setMarker(stop.stop_id, opts, hover_opts).then((map_obj) => {
        this.stop_refs[stop.stop_id] = map_obj;
        map_obj.registerEventCB('click', () => {
          console.log(
            'Create stop clicked: ' + stop.stop_id + ' ' + stop.stop_name,
          );
          this.createMarker([stop.stop_lat, stop.stop_lon], stop.stop_id, true);
        });
        map_obj.registerEventCB('mouseover', () => {
          this.MapOverlay.set(stop.stop_id, {
            text: stop.stop_name,
            coords: opts.position,
            type: 'single',
          });
        });
        map_obj.registerEventCB('mouseout', () => {
          this.MapOverlay.remove(stop.stop_id);
        });
      });
    });
    if (this.state.search_type === 'bus_stop') {
      this.showStops();
    }
  }
  showStops() {
    Object.keys(this.stop_refs).forEach((key) => {
      this.stop_refs[key].show();
    });
  }
  hideStops() {
    Object.keys(this.stop_refs).forEach((key) => {
      this.stop_refs[key].hide();
    });
  }

  updateMapFromSettings(src) {
    //This function will be called by MapOverlay and Map, when the final one is
    //finished loading it will get past this check.
    if (!this.map || !this.map.initialized || !this.MapOverlay) {
      return;
    }

    if (this.props.settings.hasOwnProperty('polylines')) {
      Object.keys(this.props.settings.polylines).forEach(
        (bulletin_polyline_id) => {
          let bulletin_polyline = this.props.settings.polylines[
            bulletin_polyline_id
          ];
          this.addPolyline(Object.assign({}, bulletin_polyline));
        },
      );
    }
    if (this.props.settings.hasOwnProperty('markers')) {
      Object.keys(this.props.settings.markers).forEach((bulletin_marker_id) => {
        let bulletin_marker = this.props.settings.markers[bulletin_marker_id];
        this.addMarker(Object.assign({}, bulletin_marker));
      });
    }
    if (this.props.settings.hasOwnProperty('texts')) {
      Object.keys(this.props.settings.texts).forEach((bulletin_text_id) => {
        let bulletin_texts = this.props.settings.texts[bulletin_text_id];
        this.addBubble(Object.assign({}, bulletin_texts));
      });
    }
  }

  saveMapChanges(changes) {
    let new_settings = Object.assign({}, this.props.settings, changes);

    new_settings.content_count = this.content_count;
    this.props.updateSettings(new_settings);
  }

  getRouteStyleByRouteShortName(route_short_name) {
    let route_style = this.props.store.config.passing_route_default_style;

    if (passing_route_styles_by_key === null) {
      //Create an object with all styles.
      passing_route_styles_by_key = {};
      this.props.store.config.passing_route_styles.forEach((style) => {
        style.aliases.forEach((alias) => {
          passing_route_styles_by_key[alias] = style.style;
        });
        if (style.alias_range && style.alias_range.length === 2) {
          for (let i = style.alias_range[0]; i <= style.alias_range[1]; i++) {
            passing_route_styles_by_key[i] = style.style;
          }
        }
      });
    }

    if (passing_route_styles_by_key.hasOwnProperty(route_short_name)) {
      route_style = passing_route_styles_by_key[route_short_name];
    }
    return Object.assign({}, route_style, {
      route_short_name: route_short_name,
      label: this.props.store_ext.getLabelForRouteShortName(route_short_name),
    });
  }

  renderCloseButton() {
    if (this.state.any_active_tool) {
      return null;
    }
    return (
      <div
        className="edit_map_close"
        onClick={() => {
          if (this.map && this.map.cutting.enabled) {
            this.map.completeCuttingMode();
          } else {
            let center = this.map.map.getCenter();
            const markings = calculateMarkings(
              this.props.map_aspect,
              this.container_ref.getBoundingClientRect(),
            );
            const NE = this.map.fromPixelToLatLng(
              markings.top,
              markings.left + markings.width,
            );
            const SW = this.map.fromPixelToLatLng(
              markings.top + markings.height,
              markings.left,
            );

            let new_center_zoom = {
              center: { lat: center.lat(), lng: center.lng() },
              markings: {
                north: NE.lat(),
                east: NE.lng(),
                south: SW.lat(),
                west: SW.lng(),
              },
              zoom: this.map.map.getZoom(),
            };
            this.saveMapChanges(new_center_zoom);
            this.props.closeView();
          }
        }}
      >
        <IconClose />
      </div>
    );
  }

  renderLegend() {
    if (this.state.any_active_tool) {
      return null;
    }
    return (
      <MapLegend
        map_settings={this.props.settings}
        use_combined_style={false}
        style_id={this.props.style_id}
        onRemove={(index, item) => {
          let updated_legend = [...this.props.settings.legend_content];
          updated_legend.splice(index, 1);
          this.saveMapChanges({ legend_content: updated_legend });
        }}
        onOrderChange={(index, to_switch) => {
          console.log(index, to_switch);
          let updated_legend = [...this.props.settings.legend_content];
          console.log('Before update: ', updated_legend);
          let temp = updated_legend[index];
          updated_legend[index] = updated_legend[to_switch];
          updated_legend[to_switch] = temp;
          console.log('After update: ', updated_legend);

          this.saveMapChanges({ legend_content: updated_legend });
        }}
      />
    );
  }

  onMapClick() {
    if (this.selected_item) {
      this.deselectMapObj();
    }
  }

  handleMapIdleEvent() {
    this.idle_timeout = null;
    const markings = calculateMarkings(
      this.props.map_aspect,
      this.container_ref.getBoundingClientRect(),
      'handleMapIdleEvent',
    );
    const NE = this.map.fromPixelToLatLng(
      markings.top,
      markings.left + markings.width,
    );
    const SW = this.map.fromPixelToLatLng(
      markings.top + markings.height,
      markings.left,
    );

    let center = this.map.map.getCenter();
    let new_center_zoom = {
      center: { lat: center.lat(), lng: center.lng() },
      zoom: this.map.map.getZoom(),
      markings: {
        north: NE.lat(),
        east: NE.lng(),
        south: SW.lat(),
        west: SW.lng(),
      },
    };
    this.saveMapChanges(new_center_zoom);
  }

  onMapIdle() {
    if (this.start_idle_done) {
      if (this.idle_timeout !== null) {
        clearTimeout(this.idle_timeout);
      }
      this.idle_timeout = setTimeout(() => {
        this.handleMapIdleEvent();
      }, 500);
      return;
    }
  }

  onMapInitialized() {
    this.start_idle_done = true;
    window.google.maps.event.trigger(this.map.map, 'resize');
    this.addStops();
    this.updateMapFromSettings('initialized idle');
    this.forceUpdate();
  }

  renderSearch() {
    if (!this.state.any_active_tool) {
      return null;
    }
    if (!this.state.search_type) {
      return null;
    }

    return (
      <FloatingSearch
        search_type={this.state.search_type}
        onMouseEnterResult={(type, id) => {
          if (type === 'route') {
            let coords = this.props.store_ext.getShapeForRoute(id);
            let opts = Object.assign(
              {
                path: this.map.helpers.arrToLatLngObj(coords),
              },
              this.bus_route_preview_opts,
            );
            this.map.setPolyline('route_preview', opts);
          }
        }}
        onMouseLeaveSearch={() => {
          this.map.setPolyline('route_preview', { visible: false });
        }}
        onClickResult={(type, id, ctrl = false) => {
          if (!this.map || this.map.initialized === false) {
            throw new Error('Map not initialized.');
          }
          this.map.setPolyline('route_preview', { visible: false });
          if (type === 'route') {
            let coords = this.props.store_ext.getShapeForRoute(id);
            if (ctrl && this.map) {
              this.map.fitToBoundsArray(coords, true);
            }
            this.createPolyLine(coords, true);
            if (this.FloatingToolsRef) {
              this.FloatingToolsRef.clearSelectedTool();
            }
          }
          if (type === 'bus_stop') {
            let stop = this.props.store.gtfs.stops[id];
            if (ctrl && this.map) {
              this.map.fitToBoundsArray([[stop.stop_lon, stop.stop_lat]]);
            }
            this.createMarker(
              [stop.stop_lat, stop.stop_lon],
              stop.stop_id,
              true,
            );
            if (this.FloatingToolsRef) {
              this.FloatingToolsRef.clearSelectedTool();
            }
          }
        }}
      />
    );
  }

  renderMapOverlay() {
    if (!this.map || !this.map.initialized) {
      return null;
    }
    return (
      <MapOverlay
        mounted={(ref) => {
          this.MapOverlay = ref;
          this.updateMapFromSettings('overlay');
        }}
        map_ref={this.map}
      />
    );
  }

  sortRoutes(a, b) {
    const a_val = a.label === a.route_short_name ? a.route_short_name : a.label;
    const b_val = b.label === b.route_short_name ? b.route_short_name : b.label;
    const a_val_int = parseInt(a_val, 10);
    const b_val_int = parseInt(b_val, 10);
    if (!isNaN(a_val_int) && !isNaN(b_val_int)) {
      return a_val_int - b_val_int;
    }

    return a.label < b.label ? -1 : 1;
  }

  renderSettingsPanel() {
    if (!this.selected_item) {
      return null;
    }
    let internal_obj = this.selected_item.internal_obj;
    switch (this.selected_item.type) {
      case 'polyline': {
        return (
          <MapSettingsPanelPolyline
            map_settings={this.props.settings}
            onLegendChange={(updated_legend) => {
              this.saveMapChanges({ legend_content: updated_legend });
            }}
            thickness={
              internal_obj.style.strokeWeight ||
              this.props.store.config.map_default_polyline_width
            }
            onThicknessChange={(new_value) => {
              internal_obj.updateStyleProp('strokeWeight', new_value);
            }}
            color={
              internal_obj.style.strokeColor ||
              this.props.store.config.map_default_polyline_color
            }
            availableColors={
              this.props.store.config.map_available_polyline_colors
            }
            onColorChange={(new_value) => {
              internal_obj.updateStyleProp('strokeColor', new_value);
            }}
            available_types={this.polyline_type_options}
            type={internal_obj.icon_id || 'solid'}
            onTypeChange={(new_value) => {
              internal_obj.updateProp('icon_id', new_value);
            }}
            onDelete={() => {
              this.removePolyline(internal_obj);
            }}
            onClose={() => {
              this.deselectMapObj();
            }}
            onSetCuttingMode={() => {
              this.map.setCuttingMode(internal_obj.id, (segments) => {
                if (!segments || segments.length === 1) {
                  this.forceUpdate();
                  return;
                }
                segments.forEach((segment) => {
                  let template = this.cleanPolyline(internal_obj);
                  this.createPolyLine(
                    this.map.helpers.latLngArrayToArrayOfArrays(segment),
                    false,
                    template,
                  );
                });
                this.removePolyline(internal_obj);
              });
              this.forceUpdate();
            }}
            cutting_mode_enabled={this.map.cutting.enabled === true}
            onCuttingModeComplete={() => {
              this.map.completeCuttingMode();
            }}
            onCuttingModeCancel={() => {
              this.map.cancelCuttingMode();
              this.selectMapObj(internal_obj, 'polyline');
            }}
          />
        );
      }
      case 'marker': {
        let passing_routes = [];
        let other_period_stop = false;
        if (internal_obj.hasOwnProperty('stop_id_reference')) {
          passing_routes = [].concat(
            this.props.store_ext.getPassingRoutesForStop(
              internal_obj.stop_id_reference,
            ),
          );
          const stop = this.props.store.gtfs.stops[
            internal_obj.stop_id_reference
          ];
          if (
            stop &&
            stop.max_table_period_id !==
              this.props.store.gtfs.selected_table_period.id
          ) {
            other_period_stop = true;
          }
        }
        let other_routes = this.props.store.gtfs.route_short_names.filter(
          (x) => !passing_routes.includes(x),
        );

        let selected_routes = [];
        if (
          internal_obj.hasOwnProperty('flyout') &&
          internal_obj.flyout.hasOwnProperty('selected_routes')
        ) {
          selected_routes = internal_obj.flyout.selected_routes;
        }
        let selected_route_short_names = selected_routes.map(
          (x) => x.route_short_name,
        );

        passing_routes = passing_routes
          .filter((x) => !selected_route_short_names.includes(x))
          .map((x) => this.getRouteStyleByRouteShortName(x));
        other_routes = other_routes
          .filter((x) => !selected_route_short_names.includes(x))
          .map((x) => this.getRouteStyleByRouteShortName(x));
        passing_routes.sort(this.sortRoutes);
        other_routes.sort(this.sortRoutes);

        return (
          <MapSettingsPanelMarker
            available_types={this.marker_type_options}
            type={internal_obj.icon_id || 'default'}
            map_settings={this.props.settings}
            onLegendChange={(updated_legend) => {
              this.saveMapChanges({ legend_content: updated_legend });
            }}
            onTypeChange={(new_value) => {
              internal_obj.updateProp('icon_id', new_value);
            }}
            text={internal_obj.flyout ? internal_obj.flyout.text : ''}
            onTextChange={(text) => {
              internal_obj.updateProp('flyout', {
                ...internal_obj.flyout,
                text,
                type: 'single',
              });
            }}
            direction={
              internal_obj.flyout
                ? internal_obj.flyout.direction || 'down'
                : 'down'
            }
            onDirectionChange={(direction) => {
              internal_obj.updateProp('flyout', {
                ...internal_obj.flyout,
                direction,
              });
            }}
            onDelete={() => {
              this.removeMarker(internal_obj);
            }}
            onClose={() => {
              this.deselectMapObj();
            }}
            other_period_stop={other_period_stop}
            other_routes={other_routes}
            passing_routes={passing_routes}
            selected_routes={selected_routes}
            addPassingRoute={(route_style) => {
              let flyout = Object.assign(
                { type: 'single', text: '' },
                internal_obj.flyout,
              );
              let selected_routes = Array.isArray(flyout.selected_routes)
                ? flyout.selected_routes
                : [];
              selected_routes.push(route_style);
              selected_routes.sort(this.sortRoutes);
              flyout.selected_routes = selected_routes;
              internal_obj.updateProp('flyout', flyout);
            }}
            removeSelectedRoute={(selected_route) => {
              let flyout = Object.assign({}, internal_obj.flyout);
              let selected_routes = flyout.selected_routes || [];
              let index = selected_routes.findIndex(
                (x) => x === selected_route,
              );
              if (index > -1) {
                selected_routes.splice(index, 1);
              }
              flyout.selected_routes = selected_routes;
              internal_obj.updateProp('flyout', flyout);
            }}
          />
        );
      }
      case 'bubble': {
        let other_routes = [...this.props.store.gtfs.route_short_names];

        const selected_routes = internal_obj.selected_routes || [];
        const selected_route_short_names = selected_routes.map(
          (x) => x.route_short_name,
        );

        other_routes = other_routes
          .filter((x) => !selected_route_short_names.includes(x))
          .map((x) => this.getRouteStyleByRouteShortName(x));
        other_routes.sort(this.sortRoutes);

        return (
          <MapSettingsPanelText
            text={internal_obj.text}
            onTextChange={(new_value) => {
              internal_obj.updateProp('text', new_value);
            }}
            selected_routes={selected_routes}
            other_routes={other_routes}
            addPassingRoute={(route_style) => {
              const selected_routes = Array.isArray(
                internal_obj.selected_routes,
              )
                ? internal_obj.selected_routes
                : [];
              selected_routes.push(route_style);
              selected_routes.sort(this.sortRoutes);
              internal_obj.updateProp('selected_routes', selected_routes);
            }}
            removeSelectedRoute={(selected_route) => {
              const selected_routes = Array.isArray(
                internal_obj.selected_routes,
              )
                ? internal_obj.selected_routes
                : [];
              let index = selected_routes.findIndex(
                (x) => x === selected_route,
              );
              if (index > -1) {
                selected_routes.splice(index, 1);
              }
              internal_obj.updateProp('selected_routes', selected_routes);
            }}
            onDelete={() => {
              this.removeBubble(internal_obj);
            }}
            onClose={() => {
              this.deselectMapObj();
            }}
          />
        );
      }
      default: {
        throw new Error('Unknown selected map object.');
      }
    }
  }

  renderPeriodSelector() {
    let selectable_options = this.props.store.gtfs.table_periods
      .sort((a, b) => {
        return a.date_start > b.date_start ? -1 : 1;
      })
      .map((table_period) => {
        return {
          id: table_period.id,
          name:
            table_period.date_start.split('T')[0] +
            ' - ' +
            (table_period.date_end ? table_period.date_end.split('T')[0] : ''),
        };
      });
    let selected_option = this.props.store.gtfs.selected_table_period.id;
    return (
      <PeriodSelectorContainer>
        <div className="dropdown_label">{oh.translate('table_period')}</div>
        <Dropdown
          block
          empty_message={oh.translate('no_periods')}
          selected={selected_option}
          options={selectable_options}
          selectedOption={(id) => {
            this.props.store_actions.spinner.add();
            gtfs_helpers.reload(id).finally(() => {
              this.props.store_actions.spinner.remove();
            });
          }}
        />
      </PeriodSelectorContainer>
    );
  }

  renderAspectMarkings() {
    if (!this.props.map_aspect || !this.container_ref) {
      return null;
    }
    const container_size = this.container_ref.getBoundingClientRect();

    const { width, height, top, left } = calculateMarkings(
      this.props.map_aspect,
      container_size,
      'renderAspectMarkings',
    );

    return (
      <div
        className="aspect_markings"
        style={{
          position: 'absolute',
          top,
          left,
          border: '3px red dashed',
          width: width + 'px',
          height: height + 'px',
          pointerEvents: 'none',
        }}
      />
    );
  }

  render() {
    let center = this.props.settings.hasOwnProperty('center')
      ? this.props.settings.center
      : this.props.store.config.default_map_center;
    let zoom = this.props.settings.hasOwnProperty('zoom')
      ? this.props.settings.zoom
      : this.props.store.config.default_map_zoom;

    return (
      <Container
        innerRef={(ref) => {
          this.container_ref = ref;
        }}
      >
        <MapBase
          api_key={this.props.store.config.google.maps.api_key}
          styles={map_style}
          defaultOptions={{
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
          }}
          defaultZoom={zoom}
          defaultCenter={center}
          ref={(ref) => {
            this.map = ref;
          }}
          onIdle={() => {
            this.onMapIdle();
          }}
          onClick={() => {
            this.onMapClick();
          }}
          initializedCB={() => {
            this.onMapInitialized();
          }}
        />
        {this.renderAspectMarkings()}
        {this.renderSearch()}
        <FloatingTools
          closeAllTools={() => this.setState({ render_search: false })}
          mapTools={this.map_tools}
          ref={(ref) => {
            this.FloatingToolsRef = ref;
          }}
        />

        {this.renderPeriodSelector()}

        {this.renderSettingsPanel()}

        {this.renderMapOverlay()}

        {this.renderLegend()}

        {this.renderCloseButton()}
        <FAQ app_section="map" h_pos={'right'} offset={{ h: 84, v: 20 }} />
      </Container>
    );
  }
}

export default withStore(EditMapView);
