import { a3_portrait } from '../../standard';
import jlt_logo from '../../../img/customer/jlt_logo_2023.svg';

const jlt = {
  red: '#e20025',
  yellow: '#ffe596',
  padding: 40,
};

let a4_affected_route_icon = {
  border_radius: 20,
  border_width: 2,
  font_size: 45,
  padding: 6,
  size: 80,
};
const route_icon_a3_factor = 1.6;
let a3_affected_route_icon = {
  border_radius: a4_affected_route_icon.border_radius * route_icon_a3_factor,
  border_width: a4_affected_route_icon.border_width * route_icon_a3_factor,
  font_size: a4_affected_route_icon.font_size * route_icon_a3_factor,
  padding: a4_affected_route_icon.padding * route_icon_a3_factor,
  size: a4_affected_route_icon.size * route_icon_a3_factor,
};

export default {
  default: {
    document_specification: `height: ${a3_portrait.height}px;
      width: ${a3_portrait.width}px;
      background-color: ${jlt.red};
      padding: 0;
      overflow: hidden;
      padding: 50px;
    `,
    OrganizationProfile: ``,
    Map: `
      height: 900px;
      position: relative;
      div.inner {
        height: 900px;
        width: 100%;
      }
    `,
    MapLegend: `
      margin: ${jlt.padding}px ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
    `,
    RouteIcons: `
      font-family: 'helvetica-neue-lt-std-75-bold';
      display: flex;
      gap: ${a4_affected_route_icon.padding * 2}px;
      padding-bottom: ${a4_affected_route_icon.padding * 2}px;
      padding-top: ${a4_affected_route_icon.padding * 2}px;
      padding-left: ${jlt.padding * 2}px;
      padding-right: ${jlt.padding * 2}px;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      & .route_icon > .border {
        border-radius: ${a4_affected_route_icon.border_radius / 2}px;
      }
      & .circle_icon > .border {
        border-radius: 1000px;
      }
      & .square_icon > .border {
        border-radius: 0px;
      }
      & .route_icon {
        padding: ${a4_affected_route_icon.padding}px;
        font-size: ${a4_affected_route_icon.font_size}px;
        border-radius: ${a4_affected_route_icon.border_radius}px;
        min-width: 116px;
        & .border {
          border-width: ${a4_affected_route_icon.border_width}px;
          padding: inherit;
        }
        &.circle_icon {
          border-radius: 1000px;
        }
        &.square_icon {
          border-radius: 0px;
        }
      }
    `,
    Text: `
      &.jlt_redirect_a3_small_map, &.jlt_redirect_a3_medium_map, &.jlt_redirect_a3_large_map, &.jlt_text_only_a3 {
        white-space: pre-wrap;
        word-wrap: break-word;
        & .weight_bold {
          font-weight: bold;
        }
        & .align_left {
          text-align: left;
        }
        & .align_right {
          text-align: right;
        }
        & .align_center {
          text-align: center;
        }
        & .italic {
          font-style: italic;
        }
        & .text_small, & .text_medium, & .text_large {
          font-family: "PTSansRegular";
          position: relative;
          font-size: 32px;
          margin: 0;
          padding-top: ${jlt.padding}px;
          padding-left: ${jlt.padding * 2}px;
          padding-right:${jlt.padding * 2}px;
        }
        & .text_medium {
          font-size: 38px;
        }
        & .text_large {
          font-size: 46px;
        }
        & .title_small, & .title_medium, & .title_large, & .title_xlarge {
          font-family: "RalewayExtraBold";
          color: ${jlt.red};
          font-size: 64px;
          padding-top: ${jlt.padding * 2}px;
          padding-left: ${jlt.padding * 2}px;
          padding-right:${jlt.padding * 2}px;
          position: relative;
          width: 100%;
          margin: 0;
        }
        & .title_medium {
          font-size: 96px;
        }
        & .title_large {
          font-size: 128px;
        }
        & .title_xlarge {
          font-size: 150px;
        }
        & .subtitle, & .subtitle_large {
          font-family: "RalewayRegular";
          color: ${jlt.red};
          font-size: 56px;
          padding: 8px ${jlt.padding * 2}px;
          position: relative;
          width: 100%;
          margin: 0;
          line-height: 64px;
        }
        & .subtitle_large {
          font-size: 64px;
          line-height: 72px;
        }
      }
      &.jlt_redirect_red_a4_v2, &.jlt_redirect_red_a3 {
        & .title_small, & .title_medium, & .title_large {
          color: #fff;
        }
        & .text_small, & .text_medium, & .text_large {
          color: #fff;
        }
        & .subtitle {
          color: #fff;
        }
      }
    `,
    QR: `
      bottom: 120px;
      width: 1000px;
      position: absolute;
      padding-left: ${jlt.padding * 2}px;
      z-index: 2;
      gap: 10px;
      display: flex;
      align-items: end;
      & .qr_text {
        font-family: RalewayBold;
        font-size: 30px;
      }
    `,
  },
  templates: [
    //A3
    {
      id: 'jlt_redirect_a3_medium_map',
      name: 'A3 Medium karta',
      types: ['redirect_v2'],
      version: 1,
      big: true,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 1.75,
      styles: {
        RouteIcons: `
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.circle_icon {
              border-radius: 1000px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        OrganizationProfile: `
          position: unset;
          background-color: ${jlt.yellow}
          .layer_1 {
            background-image: url(${jlt_logo});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 120px;
            bottom: 30px;
            width: 580px;
            height: 360px;
            z-index: 3;
          }
          .layer_2 {
            position: absolute;
            bottom: ${jlt.padding * 2}px;
            left: ${jlt.padding}px;
            right: ${jlt.padding - 10}px;
            display: block;
            height: 4000px;
            background-color: ${jlt.yellow};
            border-radius: 0 0 335px 0;
            z-index: 0;
          }
          .layer_3 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: ${jlt.padding}px solid white;
            pointer-events: none;
          }
        `,
        MapLegend: `
          padding: 8px 11px;
          border: 2px solid #000;
          border-radius: 11px;
          font-size: 22px;
          margin: 0 ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
          &.bottom_left {
            bottom: 28px;
            left: 17px;
          }
          &.bottom_right {
            bottom: 28px;
            right: 17px;
          }
          &.top_left {
            top: 28px;
            left: 17px;
          }
          &.top_right {
            top: 28px;
            right: 17px;
          }
          ul {
            li {
              margin-bottom: 3px;
              .legend_item_label {
                padding-left: 14px;
              }
              &:hover {
                .legend_item_label {
                  &:after {
                    font-size: 19px;
                  }
                }
              }
            }
          }
          .placeholder {
            width: 45px;
            height: 45px;
            & svg {
              width: 45px;
              height: 45px;
            }
          }
        `,
        Map: `
          height: calc(500px*1.82);
          position: relative;
          margin: ${jlt.padding}px ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
      },
    },
    {
      id: 'jlt_redirect_a3_small_map',
      name: 'A3 Liten karta',
      types: ['redirect_v2'],
      version: 1,
      big: true,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 2.25,
      styles: {
        RouteIcons: `
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.circle_icon {
              border-radius: 1000px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        OrganizationProfile: `
          position: unset;
          background-color: ${jlt.yellow}
          .layer_1 {
            background-image: url(${jlt_logo});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 120px;
            bottom: 30px;
            width: 580px;
            height: 360px;
            z-index: 3;
          }
          .layer_2 {
            position: absolute;
            bottom: ${jlt.padding * 2}px;
            left: ${jlt.padding}px;
            right: ${jlt.padding - 10}px;
            display: block;
            height: 4000px;
            background-color: ${jlt.yellow};
            border-radius: 0 0 335px 0;
            z-index: 0;
          }
          .layer_3 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: ${jlt.padding}px solid white;
            pointer-events: none;
          }
        `,
        MapLegend: `
          padding: 8px 11px;
          border: 2px solid #000;
          border-radius: 11px;
          font-size: 22px;
          margin: 0 ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
          &.bottom_left {
            bottom: 28px;
            left: 17px;
          }
          &.bottom_right {
            bottom: 28px;
            right: 17px;
          }
          &.top_left {
            top: 28px;
            left: 17px;
          }
          &.top_right {
            top: 28px;
            right: 17px;
          }
          ul {
            li {
              margin-bottom: 3px;
              .legend_item_label {
                padding-left: 14px;
              }
              &:hover {
                .legend_item_label {
                  &:after {
                    font-size: 19px;
                  }
                }
              }
            }
          }
          .placeholder {
            width: 45px;
            height: 45px;
            & svg {
              width: 45px;
              height: 45px;
            }
          }
        `,
        Map: `
          height: calc(500px*1.41);
          position: relative;
          margin: ${jlt.padding}px ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
      },
    },
    {
      id: 'jlt_redirect_a3_large_map',
      name: 'A3 Stor karta',
      types: ['redirect_v2'],
      version: 1,
      big: true,
      height: a3_portrait.height,
      width: a3_portrait.width,
      map_aspect: 1.31,
      styles: {
        RouteIcons: `
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: 116px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.circle_icon {
              border-radius: 1000px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        OrganizationProfile: `
          position: unset;
          background-color: ${jlt.yellow};
          .layer_1 {
            background-image: url(${jlt_logo});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 120px;
            bottom: 30px;
            width: 580px;
            height: 360px;
            z-index: 3;
          }
          .layer_2 {
            position: absolute;
            bottom: ${jlt.padding * 2}px;
            left: ${jlt.padding}px;
            right: ${jlt.padding - 10}px;
            display: block;
            height: 4000px;
            background-color: ${jlt.yellow};
            border-radius: 0 0 335px 0;
            z-index: 0;
          }
          .layer_3 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: ${jlt.padding}px solid white;
            pointer-events: none;
          }
        `,
        MapLegend: `
          padding: 8px 11px;
          border: 2px solid #000;
          border-radius: 11px;
          font-size: 22px;
          margin: 0 ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
          &.bottom_left {
            bottom: 28px;
            left: 17px;
          }
          &.bottom_right {
            bottom: 28px;
            right: 17px;
          }
          &.top_left {
            top: 28px;
            left: 17px;
          }
          &.top_right {
            top: 28px;
            right: 17px;
          }
          ul {
            li {
              margin-bottom: 3px;
              .legend_item_label {
                padding-left: 14px;
              }
              &:hover {
                .legend_item_label {
                  &:after {
                    font-size: 19px;
                  }
                }
              }
            }
          }
          .placeholder {
            width: 45px;
            height: 45px;
            & svg {
              width: 45px;
              height: 45px;
            }
          }
        `,
        Map: `
          height: calc(500px*2.41);
          position: relative;
          margin: ${jlt.padding}px ${jlt.padding * 2}px 0 ${jlt.padding * 2}px;
          div.inner {
            height: calc(500px*1.41);
            width: 100%;
          }
          div.inner img {
            position: absolute;
          }
        `,
      },
    },

    //////////
    //TEXT ONLY
    {
      id: 'jlt_text_only_a3',
      name: 'Endast text',
      types: ['text_only_v2'],
      big: true,
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        RouteIcons: `
          gap: ${a3_affected_route_icon.padding * 2}px;
          padding-bottom: ${a3_affected_route_icon.padding * 2}px;
          padding-top: ${a3_affected_route_icon.padding * 2}px;
          & .route_icon > .border {
            border-radius: ${a3_affected_route_icon.border_radius / 2}px;
          }
          & .circle_icon > .border {
            border-radius: 1000px;
          }
          & .square_icon > .border {
            border-radius: 0px;
          }
          & .route_icon {
            padding: ${a3_affected_route_icon.padding}px;
            font-size: ${a3_affected_route_icon.font_size}px;
            border-radius: ${a3_affected_route_icon.border_radius}px;
            min-width: ${a3_affected_route_icon.size}px;
            & .border {
              border-width: ${a3_affected_route_icon.border_width}px;
            }
            &.circle_icon {
              border-radius: 1000px;
            }
            &.square_icon {
              border-radius: 0px;
            }
          }
        `,
        OrganizationProfile: `
          position: unset;
          background-color: ${jlt.yellow};
          .layer_1 {
            background-image: url(${jlt_logo});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 120px;
            bottom: 30px;
            width: 580px;
            height: 360px;
            z-index: 3;
          }
          .layer_2 {
            position: absolute;
            bottom: ${jlt.padding * 2}px;
            left: ${jlt.padding}px;
            right: ${jlt.padding - 10}px;
            display: block;
            height: 4000px;
            background-color: ${jlt.yellow};
            border-radius: 0 0 335px 0;
            z-index: 0;
          }
          .layer_3 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: ${jlt.padding}px solid white;
            pointer-events: none;
          }
        `,
      },
    },

    //////////
    //MAP ONLY
    {
      id: 'jlt_map_only_a3',
      name: 'Helkarta A3',
      types: ['map_only'],
      big: true,
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        OrganizationProfile: `
          position: unset;
          background-color: ${jlt.yellow};
          .layer_1 {
            background-image: url(${jlt_logo});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: block;
            position: absolute;
            right: 120px;
            bottom: 30px;
            width: 580px;
            height: 360px;
            z-index: 3;
          }
          .layer_3 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
            border: ${jlt.padding}px solid white;
            pointer-events: none;
          }
        `,
        MapLegend: `
          padding: 8px 11px;
          border: 2px solid #000;
          border-radius: 11px;
          font-size: 22px;
          margin: 0 ${jlt.padding}px 0 ${jlt.padding}px;
          &.bottom_left {
            bottom: 28px;
            left: 17px;
          }
          &.bottom_right {
            bottom: 28px;
            right: 17px;
          }
          &.top_left {
            top: 28px;
            left: 17px;
          }
          &.top_right {
            top: 28px;
            right: 17px;
          }
          ul {
            li {
              margin-bottom: 3px;
              .legend_item_label {
                padding-left: 14px;
              }
              &:hover {
                .legend_item_label {
                  &:after {
                    font-size: 19px;
                  }
                }
              }
            }
          }
          .placeholder {
            width: 45px;
            height: 45px;
            & svg {
              width: 45px;
              height: 45px;
            }
          }
        `,
        document_specification: `
          padding: 0;
        `,
        Map: `
            height: ${a3_portrait.height - jlt.padding * 3}px;
            width: ${a3_portrait.width - jlt.padding * 2 + 10}px;
            position: relative;
            border-radius: 0 0 335px 0;
            margin: ${jlt.padding}px;
            overflow: hidden;
            div.inner {
              height: ${a3_portrait.height - jlt.padding * 3}px;
              width: calc(100% + 10px);
            }
            div.inner img {
              position: absolute;
            }
          }
        `,
      },
    },
  ],
};
