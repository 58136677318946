import React from 'react';
// import styled from 'styled-components';
import withStore from 'with-store';
import oh from 'output-helpers';
import RouteGridSelect from '../RouteGridSelect';

// const Container = styled.div`
//   ${(props) => props.styles}
// `;

let passing_route_styles_by_key = null;

const sortRoutes = (a, b) => {
  const a_val = a.label === a.route_short_name ? a.route_short_name : a.label;
  const b_val = b.label === b.route_short_name ? b.route_short_name : b.label;
  const a_val_int = parseInt(a_val, 10);
  const b_val_int = parseInt(b_val, 10);
  if (!isNaN(a_val_int) && !isNaN(b_val_int)) {
    return a_val_int - b_val_int;
  }

  return a.label < b.label ? -1 : 1;
};

const getRouteStyleByRouteShortName = (route_short_name, store, store_ext) => {
  let route_style = store.config.passing_route_default_style;

  if (passing_route_styles_by_key === null) {
    //Create an object with all styles.
    passing_route_styles_by_key = {};
    store.config.passing_route_styles.forEach((style) => {
      style.aliases.forEach((alias) => {
        passing_route_styles_by_key[alias] = style.style;
      });
      if (style.alias_range && style.alias_range.length === 2) {
        for (let i = style.alias_range[0]; i <= style.alias_range[1]; i++) {
          passing_route_styles_by_key[i] = style.style;
        }
      }
    });
  }

  if (passing_route_styles_by_key.hasOwnProperty(route_short_name)) {
    route_style = passing_route_styles_by_key[route_short_name];
  }
  return Object.assign({}, route_style, {
    route_short_name: route_short_name,
    label: store_ext.getLabelForRouteShortName(route_short_name),
  });
};

const AffectedRoutesSelector = ({
  affected_routes,
  store_ext,
  store,
  onUpdateAffectedRoutes,
}) => {
  /** A list of route_short_names that has already been added to the bulletin. */
  let affected_route_short_names = affected_routes.map(
    (r) => r.route_short_name,
  );

  /** A list of all route short_names _not_ added to the bulletin. */
  let unaffected_routes = store.gtfs.route_short_names
    .filter((x) => !affected_route_short_names.includes(x))
    .map((short_name) =>
      getRouteStyleByRouteShortName(short_name, store, store_ext),
    )
    .sort(sortRoutes);

  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        width: '100%',
        padding: '28px 22px',
        backgroundColor: 'white',
      }}
    >
      <div style={{ flexGrow: 1, flexBasis: 0 }}>
        <div
          style={{
            color: '#364161',
            fontSize: '14px',
            marginBottom: '12px',
            fontWeight: 'bold',
          }}
        >
          {oh.translate('affected_routes')}
        </div>
        <RouteGridSelect
          options={affected_routes}
          onClick={(r) => {
            onUpdateAffectedRoutes(
              affected_routes.filter(
                (a) => a.route_short_name !== r.route_short_name,
              ),
            );
          }}
        />
      </div>
      <div style={{ flexGrow: 1, flexBasis: 0 }}>
        <div
          style={{
            color: '#364161',
            fontSize: '14px',
            marginBottom: '12px',
            fontWeight: 'bold',
          }}
        >
          {oh.translate('unaffected_routes')}
        </div>
        <RouteGridSelect
          options={unaffected_routes}
          onClick={(r) => {
            onUpdateAffectedRoutes([...affected_routes, r].sort(sortRoutes));
          }}
        />
      </div>
    </div>
  );
};

export default withStore(AffectedRoutesSelector);
