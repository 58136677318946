import React from 'react';
import styled, { keyframes } from 'styled-components';
import withStore from 'with-store';
import fusejs from 'fuse.js';

const IconSearch = require('react-icons/lib/md/search');
const IconClear = require('react-icons/lib/md/clear');
const IconArrowRight = require('react-icons/lib/md/keyboard-arrow-right');
const IconArrowDown = require('react-icons/lib/md/keyboard-arrow-down');

const style = {
  b_radius: '8px',
  height: '45px',
};

const anim_slide_in = keyframes`
            from {
                transform: translateX(-300px);
            }
            to {

            }
        `;

const Container = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: ${(props) => props.theme.colors.shadow};
  border-radius: 0 0 8px 0;
  transition: all 0.3s;
  color: ${(props) => props.theme.colors.text};
  z-index: 1;
  animation: ${anim_slide_in} 1s;
  &.active {
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    ul.result {
      max-height: 100%;
      position: absolute;
      top: 68px;
      left: 0;
      right: 0;
      border-radius: 0 !important;
      bottom: 0;
      li {
        &:last-child {
          border-radius: 0 !important;
        }
      }
    }
  }
  .search_icon,
  input,
  .search_clear {
    display: inline-block;
    vertical-align: top;
  }
  .search_icon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10%;
    height: ${style.height};
    svg {
      height: 28px;
      width: 28px;
      margin: 4px;
      opacity: 0.7;
    }
  }
  .search_clear {
    box-shadow: -11px 0 5px -3px #fff;
    border-radius: 0 8px 0 0;
    background-color: #fff;
    position: absolute;
    top: 10px;
    right: 12px;
    width: 10%;
    cursor: pointer;
    height: 40px;
    &:hover {
      svg {
        opacity: 1;
      }
    }
    svg {
      opacity: 0.3;
      height: 28px;
      width: 28px;
      margin: 7px;
    }
  }
  input {
    margin: 8px;
    height: ${style.height};
    width: 340px;
    border-radius: ${style.b_radius};
    font-size: 16px;
    padding: ${(props) => props.theme.sizes.general.input_padding};
    padding-left: 38px;
    border: 2px solid ${(props) => props.theme.colors.border};
    transition: all ${(props) => props.theme.sizes.general.transition_time}s;
    &:hover,
    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.colors.brand[2]};
      & + .search_icon {
        svg {
          fill: ${(props) => props.theme.colors.brand[1]};
        }
      }
    }
  }
  ul.result {
    margin-top: 4px;
    max-height: 500px;
    overflow: auto;
    border-radius: 0 0 ${style.b_radius} 0;
    li {
      cursor: pointer;
      background-color: #fff;
      padding: 8px 14px;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
      position: relative;
      &:last-child {
        border-radius: 0 0 ${style.b_radius} 0;
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.border};
      }
      .icon_show_more {
        svg {
          margin-bottom: 4px;
          margin-right: 5px;
        }
        display: inline-flex;
      }
      .result_title {
        font-weight: bold;
        margin-bottom: 4px;
      }
      .result_title_smaller {
        font-weight: bold;
        margin-bottom: 4px;
        font-size: 15px;
      }
      .result_subtitle {
        opacity: 0.6;
        font-size: 14px;
      }
      .result_label {
        position: absolute;
        top: 14px;
        right: 14px;
        p {
          font-size: 12px;
          display: inline-block;
          background-color: ${(props) => props.theme.colors.brand[1]};
          color: #fff;
          padding: 4px 8px;
          border-radius: 50px;
        }
      }
      ul {
        margin-left: 20px;
        li {
          border-left: solid #adadad;
        }
      }
    }
  }
`;

const buildLineResults = (search_results) => {
  let results = [];
  search_results
    .sort((a, b) => {
      // The id is a string in the format of "501-9"
      // In order to properly sort on the variant number,
      // we have to split it and parse it out as a number
      let var1 = parseInt(a.id.split('-')[1], 10);
      let var2 = parseInt(b.id.split('-')[1], 10);
      return var1 - var2;
    })
    .forEach((res) => {
      let id = res.id.split('-')[0];
      if (results[id] === undefined) {
        results[id] = {};
        results[id].variants = [];
        results[id].title = res.title;
      }
      results[id].variants.push(res);
    });
  return results;
};

const showResult = (state, id) => {
  return state.shown_results.indexOf(id) !== -1;
};

const listItemShowMore = (click_event, comp) => {
  click_event.preventDefault();
  click_event.stopPropagation();
  if (!showResult(comp.state, click_event.currentTarget.id)) {
    let new_shown_state = [...comp.state.shown_results];
    new_shown_state.push(click_event.currentTarget.id);
    comp.setState({
      shown_results: new_shown_state,
    });
  } else {
    const new_shown_state = [...comp.state.shown_results];
    new_shown_state.splice(
      new_shown_state.indexOf(click_event.currentTarget.id),
      1,
    );
    comp.setState({
      shown_results: new_shown_state,
    });
  }
};

class FloatingSearch extends React.Component {
  constructor(props) {
    super();

    if (props.hasOwnProperty('search_type') === false) {
      throw new Error("Missing required parameter 'search_type'.");
    }
    if (props.hasOwnProperty('onMouseEnterResult') === false) {
      throw new Error("Missing required parameter 'onMouseEnterResult'.");
    }
    if (props.hasOwnProperty('onMouseLeaveSearch') === false) {
      throw new Error("Missing required parameter 'onMouseLeaveSearch'.");
    }
    if (props.hasOwnProperty('onClickResult') === false) {
      throw new Error("Missing required parameter 'onClickResult'.");
    }

    this.state = {
      searching: false,
      search_string: '',
      search_results: [],
      shown_results: [],
    };
  }

  componentDidMount() {
    this.updateSearchOptions();
  }

  componentDidUpdate(prev_props, prev_state) {
    if (this.props.search_type !== prev_props.search_type) {
      this.updateSearchOptions();
    }
    if (this.state.search_string !== prev_state.search_string) {
      this.doSearch(this.state.search_string);
    }
  }

  updateSearchOptions() {
    let search_options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: this.props.store.config.max_search_string_length,
      minMatchCharLength: 2,
      keys: ['title', 'subtitle'],
    };
    if (this.props.search_type === 'bus_stop') {
      search_options.keys = ['title', 'subtitle'];
      this.fuse = new fusejs(
        this.props.store_ext.getSearchOptionsForBusStops(),
        search_options,
      );
    } else if (this.props.search_type === 'bus_route') {
      search_options.keys = ['title'];
      this.fuse = new fusejs(
        this.props.store_ext.getSearchOptionsForBusRoutes(),
        search_options,
      );
    } else {
      throw new Error('Unknown search type: ' + this.props.search_type);
    }
    this.setState({
      search_string: '',
      search_results: [],
      shown_results: [],
    });
  }

  doSearch(search_str) {
    if (!search_str) {
      search_str = '';
    }
    search_str = search_str
      .trim()
      .slice(0, this.props.store.config.max_search_string_length);
    if (!this.fuse) {
      throw new Error('Fuse not initialized.');
    }

    this.setState({
      shown_results: [],
      search_results: this.fuse
        .search(search_str)
        .slice(0, 100)
        .map((r) => r.item),
    });
  }

  renderClearBtn() {
    if (this.state.search_string.length > 0) {
      return (
        <div
          className="search_clear"
          onClick={() => {
            this.setState({ search_string: '' });
          }}
        >
          <IconClear />
        </div>
      );
    }
    return null;
  }

  render() {
    let search_results = this.state.search_results;
    if (this.props.search_type === 'bus_route') {
      search_results = buildLineResults(search_results);
      return (
        <Container
          className={`floating_search ${
            search_results.length > 10 && this.state.search_string.length > 0
              ? 'active'
              : ''
          }`}
          onMouseLeave={() => {
            this.props.onMouseLeaveSearch();
          }}
        >
          <input
            value={this.state.search_string}
            onChange={(e) => this.setState({ search_string: e.target.value })}
          />
          <div className="search_icon">
            <IconSearch />
          </div>
          {this.renderClearBtn()}
          {this.state.search_string.length > 0 ? (
            <ul className="result">
              {search_results.map((item, i) => (
                <li
                  key={'result_' + i}
                  id={'result_' + i}
                  onClick={(o) => listItemShowMore(o, this)}
                >
                  <div className="result_title">
                    <div className="icon_show_more">
                      <IconArrowRight
                        id="icon-right"
                        style={{
                          display: showResult(this.state, 'result_' + i)
                            ? 'none'
                            : 'block',
                        }}
                      />
                      <IconArrowDown
                        id="icon-down"
                        style={{
                          display: showResult(this.state, 'result_' + i)
                            ? 'block'
                            : 'none',
                        }}
                      />
                      <p>{item.title}</p>
                    </div>
                  </div>
                  <ul
                    style={{
                      display: showResult(this.state, 'result_' + i)
                        ? 'block'
                        : 'none',
                    }}
                  >
                    {item.variants.map((res, j) => {
                      return (
                        <li
                          key={'result_' + j}
                          onClick={(e) => {
                            this.setState({ search_string: '' });
                            this.props.onClickResult(
                              res.type,
                              res.id,
                              e.ctrlKey || e.altKey,
                            );
                          }}
                          onMouseEnter={() => {
                            this.props.onMouseEnterResult(res.type, res.id);
                          }}
                        >
                          <div className="result_title_smaller">
                            <p>{res.title}</p>
                          </div>
                          <div className="result_subtitle">
                            <p>{res.subtitle}</p>
                          </div>
                          {res.label ? (
                            <div className="result_label">
                              <p>{res.label}</p>
                            </div>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          ) : null}
        </Container>
      );
    }

    return (
      <Container
        className={`floating_search ${
          search_results.length > 10 && this.state.search_string.length > 0
            ? 'active'
            : ''
        }`}
        onMouseLeave={() => {
          this.props.onMouseLeaveSearch();
        }}
      >
        <input
          value={this.state.search_string}
          onChange={(e) => this.setState({ search_string: e.target.value })}
        />
        <div className="search_icon">
          <IconSearch />
        </div>
        {this.renderClearBtn()}
        {this.state.search_string.length > 0 ? (
          <ul className="result">
            {this.props.search_type === 'bus_stop' ? (
              <>
                {search_results.map((item, i) => {
                  return (
                    <li
                      key={'result_' + i}
                      onClick={(e) => {
                        this.setState({ search_string: '' });
                        this.props.onClickResult(
                          item.type,
                          item.id,
                          e.ctrlKey || e.altKey,
                        );
                      }}
                      onMouseEnter={() => {
                        this.props.onMouseEnterResult(item.type, item.id);
                      }}
                    >
                      <div className="result_title">
                        <p>{item.title}</p>
                      </div>
                      <div className="result_subtitle">
                        <p>{item.subtitle}</p>
                      </div>
                      {item.label ? (
                        <div className="result_label">
                          <p>{item.label}</p>
                        </div>
                      ) : null}
                    </li>
                  );
                })}
              </>
            ) : null}
          </ul>
        ) : null}
      </Container>
    );
  }
}

export default withStore(FloatingSearch);
